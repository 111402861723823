import {
    Component,
    Inject,
    OnInit,
    Optional,
    ViewEncapsulation,
} from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import * as CamlBuilder from 'camljs';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DetailTaskComponent } from '../task/detail-task/detail-task.component';
import { locale, MbscEventcalendarView } from '@mobiscroll/angular';

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class SchedulerComponent implements OnInit {
    public schedulerReady = false;
    public config: any;
    public currentUser: any;

    public events = [];
    public resources = [];
    private currentItems = [];
    private startDate: Date;
    private endDate: Date;
    private timerangeDays = Math.round(window.innerWidth / 58.5);
    private startField: string;
    private endField: string;
    private currentUserId: number = this.sharePointService.currentUser.Id;
    view: MbscEventcalendarView = {
        timeline: {
            type: 'month',
            size: 2
        },
    };
    currentLang = locale['de'];
    get activeAssignmentsCamlQuery() {
        return new CamlBuilder()
            .Where()
            .UserField('Auftragsleiter')
            .Id()
            .EqualTo(this.currentUserId)
            .And()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag angelegt')
            .Or()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag in Arbeit')
            .ToString();
    }

    get camlQueryTasks() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .OrderBy('Modified')
            .ToString();
    }

    constructor(
        public readonly dialog: MatDialog,
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private router: Router,
        @Optional() public dialogRef: MatDialogRef<any>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        this.currentUser = this.sharePointService.currentUser;

        if (this.data && this.data.auftragsId) {
            this.startField = 'StartDate';
            this.endField = 'DueDate';

            // Get all tasks from wp
            this.sharePointService
                .getListItems({
                    title: 'Auftragsaufgaben',
                    camlQuery: this.camlQueryTasks,
                    isDocumentLibrary: false,
                    folderName: this.data.auftragsId + '',
                    recursiveAll: false,
                })
                .then((tasks) => {
                    this.currentItems = tasks;
                    this.initScheduler();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this.startField = 'Auftragsstart';
            this.endField = 'Auftragsende';

            // Get all active projects
            this.sharePointService
                .getListItems({
                    title: 'Aufträge',
                    isDocumentLibrary: false,
                    camlQuery: this.activeAssignmentsCamlQuery,
                })
                .then((projects) => {
                    this.currentItems = projects;
                    this.initScheduler();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    openDialog(clickedElementId: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.data.auftragsId,
            isTask: true,
            taskId: clickedElementId,
            arbeitsPaketId: this.data.taskId,
        };

        const dialogRefTask = this.dialog.open(
            DetailTaskComponent,
            dialogConfig
        );

        this.initAfterClose(dialogRefTask);
    }

    initAfterClose(dialogRef) {
        dialogRef.afterClosed().subscribe((result) => {
            // Get all tasks from wp
            this.sharePointService
                .getListItems({
                    title: 'Auftragsaufgaben',
                    camlQuery: this.camlQueryTasks,
                    isDocumentLibrary: false,
                    folderName: this.data.auftragsId + '',
                    recursiveAll: false,
                })
                .then((tasks) => {
                    this.currentItems = tasks;
                    this.initScheduler();
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    filter(filter) {
        switch (filter) {
            case 0:
                this.view = {
                    timeline: {
                        type: 'week',
                        size: 4
                    },
                };
                break;
            case 6:
                this.view = {
                    timeline: {
                        type: 'month',
                    },
                };
                break;
            case 1:
                this.view = {
                    timeline: {
                        type: 'year'
                    },
                };
                break;
            case 2:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 2
                    },
                };
                break;
            case 3:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 3
                    },
                };
                break;
            case 4:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 4
                    },
                };
                break;
            case 5:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 5
                    },
                };
                break;
            default:
                this.initScheduler();
                break;
        }
    }

    initScheduler() {
        const self = this;

        this.startDate = new Date();
        this.endDate = new Date();

        this.resources = [];
        this.events = [];

        this.currentItems.forEach((item) => {
            if (new Date(item[this.startField]) < this.startDate) {
                this.startDate = new Date(item[this.startField]);
            }

            if (new Date(item[this.endField]) > this.endDate) {
                this.endDate = new Date(item[this.endField]);
            }

            this.resources.push({
                name: item.Title,
                id: item.Id,
                expanded: false,
                // children: [{ name: 'Production Line A.2', id: 'R2' }]
            });

            this.events.push({
                id: item.Id,
                start: this.addHours(
                    new Date(item[this.startField]),
                    2
                ).toISOString(),
                end: this.addHours(
                    new Date(item[this.endField]),
                    3
                ).toISOString(),
                resource: item.Id,
                text: item.Title,
                bubbleHtml:
                    '<b>' +
                    item.Title +
                    '</b></br>' +
                    this._translateService.instant('START') +
                    ': ' +
                    new Date(item[this.startField]).toLocaleDateString() +
                    '</br>' +
                    this._translateService.instant('END') +
                    ': ' +
                    new Date(item[this.endField]).toLocaleDateString(),
            });
        });

        if (
            this.timerangeDays <
            Math.ceil((+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24))
        ) {
            this.timerangeDays = Math.ceil(
                (+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24)
            );
        }

        
    }

    addHours(date: Date, h: number): Date {
        const result = new Date(date);
        result.setTime(date.getTime() + h * 60 * 60 * 1000);

        return result;
    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    onCellClick(event: any): void {
        this.openDialog(event.event.id)
    }
}
