<div class="page-layout carded left-sidebar tabbed inner-scroll">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar" name="carded-left-sidebar-tabbed-2" position="left" lockedOpen="gt-md">

    <!-- SIDEBAR HEADER -->
    <div class="header p-6 accent">
      <h2 class="m-0"></h2>
    </div>
    <!-- / SIDEBAR HEADER -->

    <!-- SIDEBAR CONTENT -->
    <div class="content" fusePerfectScrollbar [@animate]="{ value: '*', params: { y: '50px' } }">
      <div class="nav material2">

        <div class="nav-subheader">

        </div>

        <div *ngFor="let item of this._applicationService.sideBarConfig" class="nav-item">
          <a class="nav-link" matRipple [routerLink]="item.Link" [routerLinkActive]="['active', 'accent']"
            queryParamsHandling="merge" (click)="onNavChange()">
            <mat-icon class="nav-link-icon">{{ item.Icon }}</mat-icon>
            <span>{{ item.Text }}</span>
          </a>
        </div>

      </div>
    </div>
    <!-- / SIDEBAR CONTENT -->

  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header accent p-6" fxLayout="row" fxLayoutAlign="space-between center">

      <div fxLayout="column" fxLayoutAlign="center start">

        <div class="h2 mt-4">
          <mat-icon class="logo-icon mr-4" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">assignment
          </mat-icon>
          <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
            {{ this.itemTitle }}
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="secondary-text s-18">arrow_right</mat-icon>
          <span class="secondary-text">{{ "TIMELINE.PROJECTS" | translate }}</span>
          <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
          <span class="secondary-text">{{ "TIMELINE.TIMELINE" | translate }}</span>
        </div>

      </div>

      <!-- SEARCH -->
      <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

              <div class="search-wrapper mt-4 mt-sm-0">

                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>search</mat-icon>
                  <input [formControl]="searchInput" placeholder="Search for a contact">
                </div>

              </div>

            </div> -->
      <!-- / SEARCH -->

    </div>
    <!-- / CONTENT HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card">

      <!-- CONTENT TOOLBAR -->
      <div class="toolbar px-24 py-8">

        <button mat-icon-button class="sidebar-toggle" fxHide.gt-md>
          <mat-icon>menu</mat-icon>
        </button>

        <div class="header">{{ this.header }}</div>

      </div>
      <!-- / CONTENT TOOLBAR -->

      <!-- CONTENT -->
      <div class="content card-content">

        <button mat-raised-button color="accent" (click)="filter(0)">{{ "TIMELINE.CURRENT" | translate }}</button>
        <button mat-raised-button color="accent" (click)="filter(6)">{{ "TIMELINE.THISMONTH" | translate }}</button>
        <button mat-raised-button color="accent" (click)="filter(1)">{{ "TIMELINE.THISYEAR" | translate }}</button>
        <button mat-raised-button color="accent" (click)="filter(2)">{{ "TIMELINE.NEXTYEAR" | translate }}</button>
        <button mat-raised-button color="accent" (click)="filter(3)">{{ "TIMELINE.THREEYEARS" | translate }}</button>
        <button mat-raised-button color="accent" (click)="filter(4)">{{ "TIMELINE.FOURYEARS" | translate }}</button>
        <button mat-raised-button color="accent" (click)="filter(5)">{{ "TIMELINE.FIVEYEARS" | translate }}</button>

        <div class="scheduler">
          <mbsc-eventcalendar [view]="view" [data]="events" [resources]="resources" [locale]="currentLang"
            (onEventClick)="onCellClick($event)"></mbsc-eventcalendar>
        </div>

      </div>
      <!-- / CONTENT -->

    </div>
    <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>