import { NgModule } from '@angular/core';
import { KanbanComponent } from './kanban.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';

const routes = [
    {
        path: 'kanban',
        component: KanbanComponent,
    },
];

@NgModule({
    declarations: [KanbanComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        DragDropModule,
    ],
    exports: [KanbanComponent],
})
export class KanbanModule {}
