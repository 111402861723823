import { SharePointService } from 'sp-office365-framework';
import { MailService as MailService365 } from 'sp-office365-layout';
import { Injectable } from '@angular/core';
import { Mail } from '../models/mail.model';
import { IFetchOptions } from '@pnp/common/net';
import CamlBuilder from 'camljs';
import { combineLatest, Subject } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import '@pnp/graph/messages';

@Injectable({
    providedIn: 'root',
})
export class MailService {
    public attachmentFiles = [];
    public filterEmails$ = new Subject<string>();
    public filterFromEmails$ = new Subject<string>();
    public filterToEmails$ = new Subject<string>();

    constructor(
        private _sharePointService: SharePointService,
        private _mailService365: MailService365
    ) {}

    sendMail(mail: Mail): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];
            const receiver = [];
            const ccReceiver = [];
            const attachments = [];
            let from;

            mail.To.forEach((email) => {
                receiver.push({
                    emailAddress: {
                        address: email,
                    },
                });
            });

            if (mail.CC) {
                mail.CC.forEach((email) => {
                    ccReceiver.push({
                        emailAddress: {
                            address: email,
                        },
                    });
                });
            }

            if (mail.From) {
                from = {
                    emailAddress: { name: mail.From, address: mail.From },
                };
            }

            if (mail.AttachmentServerRelativeUrls) {
                mail.AttachmentServerRelativeUrls.forEach((url) => {
                    promises.push(
                        new Promise<void>((resolve, reject) => {
                            this._sharePointService.web
                                .getFileByServerRelativePath(url)
                                .getBuffer()
                                .then((buffer: ArrayBuffer) => {
                                    attachments.push({
                                        '@odata.type':
                                            '#microsoft.graph.fileAttachment',
                                        name: url.split('/').pop(),
                                        contentType: 'text/plain',
                                        contentBytes:
                                            this.bufferToBase64(buffer),
                                    });
                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                    );
                });
            }

            Promise.all(promises)
                .then(() => {
                    let message: any = {
                        message: {
                            subject: mail.Subject,
                            body: {
                                contentType: 'HTML',
                                content: mail.Content,
                            },
                            toRecipients: receiver,
                            ccRecipients: ccReceiver,
                            attachments,
                            from,
                        },
                        saveToSentItems: true,
                    };

                    // graph.me.messages.
                    this._sharePointService.graph.me
                        .sendMail(message)
                        .then((result) => {
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getMailsFromMailFolder(folderName: string, top?: number): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (!top) {
                top = 10;
            }

            this.getMailfoldersFromUser()
                .then((folders) => {
                    let mailFolder = folders.find(
                        (x) => x.displayName == folderName
                    );
                    this._sharePointService.graph.me.messages
                        .filter(`ParentFolderId eq '` + mailFolder.id + `'`)
                        .top(top)()
                        .then((messages: any[]) => {
                            resolve(messages);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getMailsFromMailFolderById(folderId: string, top?: number): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (!top) {
                top = 10;
            }

            this.getMailfolderById(folderId)
                .then((mailFolder) => {
                    this._sharePointService.graph.me.messages
                        .filter(`ParentFolderId eq '` + mailFolder.id + `'`)
                        .top(top)
                        .expand('attachments')()
                        .then((messages: any[]) => {
                            resolve(messages);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getMailfoldersFromUser(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._sharePointService.graph.me.mailFolders
                .top(1000)
                .expand('childFolders')()
                .then((folders: any[]) => {
                    resolve(folders);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getMailfolderById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            let options: IFetchOptions;
            this._sharePointService.graph.me.mailFolders
                .getById(id)
                .expand('childFolders')()
                .then((folder) => {
                    resolve(folder);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getchildMailfolderById(folder): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._sharePointService.graph.me.mailFolders
                .getById(folder)
                .expand('childFolders')()
                .then((rfolder: any) => {
                    let cFolders = [];
                    rfolder.childFolders.forEach((e) => {
                        cFolders.push({
                            id: e.id,
                            displayName: e.displayName,
                            parentFolderId: e.parentFolderId,
                            childFolderCount: e.childFolderCount,
                        });
                    });
                    resolve(cFolders);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private bufferToBase64(buffer) {
        var arr = new Uint8Array(buffer);

        //  Don't use fromCharCode.apply as it blows the stack with moderate size images
        var raw = '';
        for (var i = 0; i < arr.length; i++) {
            raw = raw + String.fromCharCode(arr[i]);
        }

        return window.btoa(raw);
    }

    public static checkForDuplicateEmail(
        emails: Mail[],
        element: Mail
    ): boolean {
        const currentEmailBody = element.Body?.replace(/&#58;/gi, ':').replace(
            /(<style.*?>[^>]+<\/style>)|(<([^>]+)>)|(&.*?;)|\r|\n/gi,
            ''
        );
        return emails.some((email) => {
            const incomingEmailBody = email.Body?.replace(
                /&#58;/gi,
                ':'
            ).replace(
                /(<style.*?>[^>]+<\/style>)|(<([^>]+)>)|(&.*?;)|\r|\n/gi,
                ''
            );
            return (
                email.To === element.To &&
                email.From === element.From &&
                incomingEmailBody === currentEmailBody &&
                email.Subject === element.Subject
            );
        });
    }

    public async findDuplicates(
        listTitle: string,
        projectId: number,
        inputEmails: any[]
    ): Promise<Record<string, boolean>> {
        const duplicateEmails: Record<string, boolean> = {};
        const existedEmails = await this._sharePointService.getListItems({
            title: listTitle,
            recursiveAll: false,
            folderName: projectId + '',
            camlQuery: new CamlBuilder()
                .Where()
                .LookupField(listTitle === 'Mailbox' ? 'Projekt' : 'Antrag')
                .Id()
                .EqualTo(projectId)
                .ToString(),
            isDocumentLibrary: false,
        });

        inputEmails.forEach((mail) => {
            if (MailService.checkForDuplicateEmail(existedEmails, mail)) {
                duplicateEmails[mail.ID] = true;
            }
        });

        return duplicateEmails;
    }

    // Shadow validation
    public validateEmail(email: string): boolean {
        const emails = email.split(';');
        return emails.every((email) => /\S+@\S+\.\S+/.test(email));
    }

    public static assignMailToTask(
        mailIds: number[],
        mailsSource: any[]
    ): void {
        mailsSource.forEach((mail) => {
            if (mailIds.includes(mail.id)) {
                mail.isTaskMail = true;
            }
        });
    }

    public markMailAsTaskRelated(taskId: number): void {
        this._mailService365.onMailTaskChanged.next(taskId);
    }

    public sortMailsByDateDESC(config: {
        mails: { timeAsDate: Date }[];
    }): void {
        config.mails = config.mails.sort(
            (a, b) => b.timeAsDate.getTime() - a.timeAsDate.getTime()
        );
    }

    public listenEmailFilter() {
        return combineLatest([
            this.filterEmails$.pipe(startWith(null)),
            this.filterFromEmails$.pipe(startWith(null)),
            this.filterToEmails$.pipe(startWith(null)),
        ]).pipe(
            filter(
                ([commonQuery, fromUser, toUser]) =>
                    commonQuery !== null || fromUser !== null || toUser !== null
            )
        );
    }
}
