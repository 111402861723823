import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Inject,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { DetailTaskComponent } from '../../task/detail-task/detail-task.component';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
    FrameworkService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { from, Observable } from 'rxjs';
import * as CamlBuilder from 'camljs';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ApplicationService } from '../../services/application.service';
import { DetailCustomerComponent } from '../../customer/detail-customer/detail-customer.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { German } from 'flatpickr/dist/l10n/de.js';
import { locale, MbscEventcalendarView } from '@mobiscroll/angular';

@Component({
    selector: 'app-detail-involved',
    templateUrl: './detail-involved.component.html',
    styleUrls: ['./detail-involved.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailInvolvedComponent implements OnInit {
    @Input() auftragsId: number;
    @Input() id: number;

    public internUserId;
    public externUserId;
    public showScheduler = false;
    public isCreateMode = true;
    public firstTimeLoad = true;
    public isExtern = false;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    public sharePointItem;
    public title;
    public formioConfiguration;
    public schedulerConfig: any;

    public resources = [];
    public events = [];
    private searchGroups: string;
    view: MbscEventcalendarView = {
        timeline: {
            type: 'month',
            size: 2
        },
    };
    currentLang = locale['de'];
    public formio_renderOptions = {
        // language: 'de'
    };

    get internInvolvedWithUserCamlQuery() {
        return new CamlBuilder()
            .Where()
            .UserField('Person_x0020_AD')
            .Id()
            .EqualTo(this.internUserId)
            .ToString();
    }

    get externInvolvedWithUserCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Kontakt')
            .Id()
            .EqualTo(this.externUserId)
            .ToString();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DetailTaskComponent>,
        private readonly _sharePointService: SharePointService,
        private readonly _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private readonly _translateService: TranslateService,
        private readonly _loadingService: LoadingService,
        private readonly _snackBarService: SnackbarService,
        private readonly _applicationService: ApplicationService,
        private readonly _frameworkService: FrameworkService,
        private readonly _updateService: UpdateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        const promises = [];

        this.id = this.data.involvedId;
        this.auftragsId = this.data.auftragsId;

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._updateService
                    .getPropertyValue('Usergruppen')
                    .then((value) => {
                        this.searchGroups = value;

                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        if (this.id) {
            this.isCreateMode = false;

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this._sharePointService
                        .getItemById({
                            id: this.id,
                            listTitle: 'Auftragsbeteiligte',
                        })
                        .then((involved) => {
                            this.sharePointItem = involved;
                            this.isExtern = this.sharePointItem.Art == 'extern';

                            if (
                                !this.isExtern &&
                                this.sharePointItem.Person_x0020_AD
                            ) {
                                this.internUserId =
                                    this.sharePointItem.Person_x0020_AD;
                                this.loadDataForScheduler();
                            } else if (
                                this.isExtern &&
                                this.sharePointItem.KontaktId
                            ) {
                                this.externUserId =
                                    this.sharePointItem.KontaktId;
                                this.loadDataForScheduler();
                            }

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );
        }

        Promise.all(promises)
            .then(() => {
                this.initForm();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onTabChange(e) {
        //
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e): Observable<void> {
        this._loadingService.show(
            this._translateService.instant('NOTIFICATIONS.SAVING'),
            this._translateService.instant('NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        // Set values
        if (this.title) {
            e.data.Title = this.title;
        }

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.id) {
            // Set values on edit
            return from(
                this._sharePointService
                    .updateItem({
                        id: this.id,
                        listTitle: 'Auftragsbeteiligte',
                        newFiledValues: e.data,
                    })
                    .then(() => {
                        this._loadingService.hide();

                        this._snackBarService.add(
                            this._translateService.instant(
                                'NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close('success');
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        } else {
            // Set values on create
            if (this.auftragsId) {
                e.data.AuftragId = this.auftragsId;
            }

            return from(
                this._sharePointService
                    .addItem({
                        listTitle: 'Auftragsbeteiligte',
                        data: e.data,
                        folder: this.auftragsId.toString(),
                    })
                    .then(() => {
                        this._loadingService.hide();

                        this._snackBarService.add(
                            this._translateService.instant(
                                'NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close('success');
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        }
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    setTitleForUser(): void {
        if (!this.isExtern && this.internUserId) {
            this._frameworkService
                .getUserOrGroupById(this.internUserId)
                .then((user) => {
                    this.title = user.Title;
                })
                .catch((error) => {
                    console.error(error);
                });
        } else if (this.isExtern && this.externUserId) {
            this._sharePointService
                .getItemById({ listTitle: 'Kontakte', id: this.externUserId })
                .then((contact) => {
                    this.title = contact.Title;

                    if (contact.FirstName) {
                        this.title = contact.Title + ' ' + contact.FirstName;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
            } else if (ev.changed.component.key === 'Art') {
                // Art Changed
                this.isExtern = ev.data.Art == 'extern';

                // Hide/Show fields
                const contactComponent = this.formioConfiguration.components
                    .find((x) => x.key == 'columns1')
                    .columns.find((x) => x.key == 'column2')
                    .components.find((x) => x.key == 'KontaktId');
                contactComponent.hidden = !this.isExtern;

                const userComponent = this.formioConfiguration.components
                    .find((x) => x.key == 'columns1')
                    .columns.find((x) => x.key == 'column2')
                    .components.find((x) => x.key == 'Person_x0020_ADId');
                userComponent.hidden = this.isExtern;

                // if (this.extern) {
                //     contactComponent.validate = {
                //         customMessage: '{{field}} ist ein Pflichtfeld',
                //         required: true,
                //     };
                //     userComponent.validate = null;
                // } else {
                //     userComponent.validate = {
                //         customMessage: '{{field}} ist ein Pflichtfeld',
                //         required: true,
                //     };
                //     contactComponent.validate = null;
                // }

                // Update fields and values in form
                this.refreshForm.emit({
                    form: this.formioConfiguration,
                    submission: {
                        data: ev.data,
                    },
                });
            }
        }
    }

    loadDataForScheduler() {
        let query = '';

        if (this.isExtern) {
            query = this.externInvolvedWithUserCamlQuery;
        } else {
            query = this.internInvolvedWithUserCamlQuery;
        }

        this._sharePointService
            .getListItems({
                title: 'Auftragsbeteiligte',
                isDocumentLibrary: false,
                camlQuery: query,
                recursiveAll: true,
            })
            .then((involved) => {
                const projects = [];
                const promises = [];

                involved.forEach((element) => {
                    if (element.AuftragId) {
                        promises.push(
                            this._sharePointService
                                .getItemById({
                                    listTitle: 'Aufträge',
                                    id: element.AuftragId,
                                })
                                .then((assignment) => {
                                    projects.push({
                                        assignment,
                                        involved: element,
                                    });
                                })
                                .catch((error) => {
                                    console.error(error);
                                })
                        );
                    }
                });

                Promise.all(promises)
                    .then(() => {
                        if (projects.length > 0) {
                            this.showScheduler = true;
                            this.initScheduler(projects);
                        } else {
                            this.showScheduler = false;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    initScheduler(data) {
        const self = this;

        let startDate = new Date();
        let endDate = new Date();
        let timerangeDays = Math.round(window.innerWidth / 117);
        let count = 0;

        data.forEach((dataValue) => {
            const project = dataValue.assignment;
            const involved = dataValue.involved;

            if (new Date(involved.Start) < startDate) {
                startDate = new Date(involved.Start);
            }

            if (new Date(involved.Ende) > endDate) {
                endDate = new Date(involved.Ende);
            }

            if (this.resources.filter((x) => x.id == project.Id).length == 0) {
                this.resources.push({
                    name: project.Title,
                    id: project.Id,
                    expanded: false,
                    // children: [{ name: 'Production Line A.2', id: 'R2' }]
                });
            }

            this.events.push({
                id: count,
                start: this.addHours(new Date(involved.Start), 2).toISOString(),
                end: this.addHours(new Date(involved.Ende), 3).toISOString(),
                resource: project.Id,
                text:
                    this._translateService.instant('INVOLVEMENT') +
                    ': ' +
                    involved.Beteiligung +
                    ' ' +
                    this._translateService.instant('COLUMNS.INPERCENT'),
                bubbleHtml:
                    '<b>' +
                    project.Title +
                    '</b></br>' +
                    this._translateService.instant('COLUMNS.START') +
                    ': ' +
                    new Date(involved.Start).toLocaleDateString() +
                    '</br>' +
                    this._translateService.instant('COLUMNS.END') +
                    ': ' +
                    new Date(involved.Ende).toLocaleDateString() +
                    '</br>' +
                    this._translateService.instant('COLUMNS.INPERCENT') +
                    ': ' +
                    involved.Beteiligung,
            });
            count++;
        });

        if (
            timerangeDays <
            Math.ceil((+endDate - +startDate) / (1000 * 60 * 60 * 24))
        ) {
            timerangeDays = Math.ceil(
                (+endDate - +startDate) / (1000 * 60 * 60 * 24)
            );
        }
    }

    addHours(date: Date, h: number): Date {
        const result = new Date(date);
        result.setTime(date.getTime() + h * 60 * 60 * 1000);

        return result;
    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'COLUMNS.KIND'
                                    ),
                                    type: 'select',
                                    key: 'Art',
                                    defaultValue: 'intern',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNS.INTERN'
                                                ),
                                                value: 'intern',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNS.EXTERN'
                                                ),
                                                value: 'extern',
                                            },
                                        ],
                                    },
                                    valueProperty: 'value',
                                    selectThreshold: 0.3,
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                            ],
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'COLUMNS.USER'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'Person_x0020_ADId',
                                    selectThreshold: 0.3,
                                    isPeoplePicker: true,
                                    // useGraph: !window['useApp'],
                                    onSelected: (control, data) => {
                                        if (data.Id) {
                                            this.internUserId = data.Id;
                                            this.loadDataForScheduler();
                                            this.setTitleForUser();
                                        }
                                    },
                                    removable: true,
                                    searchGroups: this.searchGroups,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    isCreateMode: this.isCreateMode,
                                    hidden: this.isExtern,
                                },
                                {
                                    label: this._translateService.instant(
                                        'COLUMNS.CONTACT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'KontaktId',
                                    selectThreshold: 0.3,
                                    list: 'Kontakte',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    onSelected: (control, data) => {
                                        if (data.Id) {
                                            this.externUserId = data.Id;
                                            this.loadDataForScheduler();
                                            this.setTitleForUser();
                                        }
                                    },
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    isCreateMode: this.isCreateMode,
                                    hidden: !this.isExtern,
                                    addComponent: DetailCustomerComponent,
                                },
                            ],
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column2',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'COLUMNS.START'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'datetime',
                                    input: true,
                                    key: 'Start',
                                    suffix: true,
                                    defaultValue: '',
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'COLUMNS.PARTICIPATION'
                                    ),
                                    hideLabel: false,
                                    placeholder: '',
                                    description:
                                        this._translateService.instant(
                                            'COLUMNS.INPERCENT'
                                        ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'textfield',
                                    input: true,
                                    key: 'Beteiligung',
                                    delimiter: false,
                                    requireDecimal: false,
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'COLUMNS.END'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'datetime',
                                    input: true,
                                    key: 'Ende',
                                    suffix: true,
                                    defaultValue: '',
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy HH:mm',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant('COLUMNS.PROJECT'),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'AuftragId',
                    setValue: this.auftragsId,
                    list: 'Aufträge',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .TextField('Title')
                        .Contains('<%searchText%>')
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    disabled: true,
                    isCreateMode: this.isCreateMode,
                    hidden: true,
                },
                {
                    label: this._translateService.instant('COLUMNS.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }
}
