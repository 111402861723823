import { NgModule } from '@angular/core';
import { DocTemplatesComponent } from './doc-templates.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

const routes = [
    {
        path: 'administration/templates',
        component: DocTemplatesComponent,
    },
];

@NgModule({
    declarations: [DocTemplatesComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatExpansionModule,
        AngularFrameworkModule,
    ],
    exports: [DocTemplatesComponent],
})
export class DocTemplatesModule {}
