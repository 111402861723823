import { LoadingService } from './loading.service';
import { Injectable } from '@angular/core';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import {
    FrameworkService,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import * as CamlBuilder from 'camljs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ConfirmDialogTasksComponent } from '../task/detail-task/confirm-dialog-tasks/confirm-dialog-tasks.component';
import { ICamlQuery, IItem, IList } from '@pnp/sp/presets/all';
import { RoleTypeKind } from '@pnp/sp/security/types';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormioComponent } from '@formio/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AssignmentBudgetService } from 'src/app/main/services/assignment-budget.service';
import { CurrentUserService } from './current-user.service';
import { UserAgentApplication } from 'msal';
import { InjectHeaders } from '@pnp/queryable';

declare var SP: any;

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    public serverUrl: string;
    public editorPermissions = [];
    public readerPermissions = [];
    public sideBarConfig = [];
    public currentProjectId = -1;
    public timesEnabled = false;

    public confirmDialogRef: MatDialogRef<ConfirmDialogTasksComponent>;

    private dependantFolderLists = [
        { listTitle: 'Auftragsdokumente', isDocumentLibrary: true },
        { listTitle: 'Aufgabendokumente', isDocumentLibrary: true },
        { listTitle: 'Auftragsaufgaben', isDocumentLibrary: false },
        { listTitle: 'Auftragsbeteiligte', isDocumentLibrary: false },
        { listTitle: 'Projektkommentare', isDocumentLibrary: false },
        { listTitle: 'Projektnotizen', isDocumentLibrary: true },
        { listTitle: 'Mailbox', isDocumentLibrary: false },
        { listTitle: 'Zeiten', isDocumentLibrary: false },
        { listTitle: 'Rechnungen', isDocumentLibrary: false },
    ];

    constructor(
        public dialog: MatDialog,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _updateService: UpdateService,
        private _loadingService: LoadingService,
        private _frameworkService: FrameworkService,
        private _assignmentBudgetService: AssignmentBudgetService,
        private _currentUserService: CurrentUserService,
        private http: HttpClient
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        if (this._sharepointService.sphostUrl) {
            const pathArray = this._sharepointService.sphostUrl.split('/');
            const protocol = pathArray[0];
            const host = pathArray[2];

            this.serverUrl = decodeURIComponent(protocol + '//' + host);
        }
    }

    public callBeforeSaveFunctions(formioConfiguration, data): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            // beforeSave function aufrufen
            formioConfiguration.components.forEach((element) => {
                if (element.customOptions && element.customOptions.beforeSave) {
                    promises.push(
                        element.customOptions.beforeSave(element, data)
                    );
                }
                if (element.type === 'columns') {
                    element.columns.forEach((column) => {
                        column.components.forEach((columnElement) => {
                            if (
                                columnElement.customOptions &&
                                columnElement.customOptions.beforeSave
                            ) {
                                promises.push(
                                    columnElement.customOptions.beforeSave(
                                        columnElement,
                                        data
                                    )
                                );
                            }
                        });
                    });
                } else if (element.type === 'tabs') {
                    element.components.forEach((tabElement) => {
                        tabElement.components.forEach((columnElement) => {
                            if (
                                columnElement.customOptions &&
                                columnElement.customOptions.beforeSave
                            ) {
                                promises.push(
                                    columnElement.customOptions.beforeSave(
                                        columnElement,
                                        data
                                    )
                                );
                            }

                            if (columnElement.type === 'columns') {
                                columnElement.columns.forEach((columnInTab) => {
                                    columnInTab.components.forEach(
                                        (columnInTabElement) => {
                                            if (
                                                columnInTabElement.customOptions &&
                                                columnInTabElement.customOptions
                                                    .beforeSave
                                            ) {
                                                promises.push(
                                                    columnInTabElement.customOptions.beforeSave(
                                                        columnInTabElement,
                                                        data
                                                    )
                                                );
                                            }
                                        }
                                    );
                                });
                            }
                        });
                    });
                }
            });

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public callBeforeLoadFunctions(formioConfiguration, data): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            // beforeLoad function aufrufen
            formioConfiguration.components.forEach((element) => {
                if (element.customOptions && element.customOptions.beforeLoad) {
                    promises.push(
                        element.customOptions.beforeLoad(element, data)
                    );
                }
                if (element.type === 'columns') {
                    element.columns.forEach((column) => {
                        column.components.forEach((columnElement) => {
                            if (
                                columnElement.customOptions &&
                                columnElement.customOptions.beforeLoad
                            ) {
                                promises.push(
                                    columnElement.customOptions.beforeLoad(
                                        columnElement,
                                        data
                                    )
                                );
                            }
                        });
                    });
                } else if (element.type === 'tabs') {
                    element.components.forEach((tabElement) => {
                        tabElement.components.forEach((columnElement) => {
                            if (
                                columnElement.customOptions &&
                                columnElement.customOptions.beforeLoad
                            ) {
                                promises.push(
                                    columnElement.customOptions.beforeLoad(
                                        columnElement,
                                        data
                                    )
                                );
                            }

                            if (columnElement.type === 'columns') {
                                columnElement.columns.forEach((columnInTab) => {
                                    columnInTab.components.forEach(
                                        (columnInTabElement) => {
                                            if (
                                                columnInTabElement.customOptions &&
                                                columnInTabElement.customOptions
                                                    .beforeLoad
                                            ) {
                                                promises.push(
                                                    columnInTabElement.customOptions.beforeLoad(
                                                        columnInTabElement,
                                                        data
                                                    )
                                                );
                                            }
                                        }
                                    );
                                });
                            }
                        });
                    });
                }
            });

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public beforeSaveFunctionLookupWithDocs(
        formio,
        config,
        data
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            const promises = [];

            const currentComponent = this.getFormioComponentById(
                formio.components,
                config.id
            );

            if (currentComponent.tempFiles) {
                // Upload tempfiles
                currentComponent.tempFiles.forEach((file) => {
                    if (!window['useApp']) {
                        // MSAL
                        promises.push(
                            new Promise((resolve, reject) => {
                                this._sharepointService
                                    .uploadDocumentMSAL(
                                        this.computeServerRelativeUrl(
                                            this._sharepointService.sphostUrl
                                        ) +
                                            '/' +
                                            config.list,
                                        file.file
                                    )
                                    .then((result: any) => {
                                        this.loadItemFromFile(
                                            result.data.ServerRelativeUrl
                                        )
                                            .then((data) => {
                                                resolve(data.item.Id);
                                            })
                                            .catch((error) => {
                                                reject(error);
                                            });
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                        );
                    } else {
                        // APP
                        promises.push(
                            new Promise((resolve, reject) => {
                                this._sharepointService
                                    .uploadDocument(file.file, '', config.list)
                                    .then((result: any) => {
                                        this.loadItemFromFile(
                                            result.data.ServerRelativeUrl
                                        )
                                            .then((data) => {
                                                resolve(data.item.Id);
                                            })
                                            .catch((error) => {
                                                reject(error);
                                            });
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                        );
                    }
                });
            }

            Promise.all(promises)
                .then((results) => {
                    if (results.length > 0) {
                        if (config.multi) {
                            // Multi
                            data[config.key] = {
                                results,
                            };
                        } else {
                            // Single
                            data[config.key] = results[0];
                        }
                    }
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getFormioComponentById(components, id): any {
        let component = components.find((x) => x.id == id);

        const componentsWithChildren = components.filter(
            (x) => x.components != null || x.columns != null
        );

        if (component != null) {
            return component;
        } else if (
            componentsWithChildren != null &&
            componentsWithChildren.length > 0
        ) {
            componentsWithChildren.forEach((componentWithChildren) => {
                if (component == null) {
                    if (componentWithChildren.components) {
                        component = this.getFormioComponentByKey(
                            componentWithChildren.components,
                            id
                        );
                    } else if (componentWithChildren.columns) {
                        component = this.getFormioComponentByKey(
                            componentWithChildren.columns,
                            id
                        );
                    }
                }
            });

            return component;
        } else {
            return component;
        }
    }

    public getFormioComponentByKey(components, key): any {
        let component = components.find((x) => x.key == key);

        const componentsWithChildren = components.filter(
            (x) => x.components != null || x.columns != null
        );

        if (component != null) {
            return component;
        } else if (
            componentsWithChildren != null &&
            componentsWithChildren.length > 0
        ) {
            componentsWithChildren.forEach((componentWithChildren) => {
                if (component == null) {
                    if (componentWithChildren.components) {
                        component = this.getFormioComponentByKey(
                            componentWithChildren.components,
                            key
                        );
                    } else if (componentWithChildren.columns) {
                        component = this.getFormioComponentByKey(
                            componentWithChildren.columns,
                            key
                        );
                    }
                }
            });

            return component;
        } else {
            return component;
        }
    }

    public setVisibilityOfFormioComponent(
        formioComponent: FormioComponent,
        componentKey: string,
        hidden: boolean
    ) {
        let component = this.getFormioComponentByKey(
            formioComponent.formio.components,
            componentKey
        );

        if (component) {
            component['hidden'] = hidden;

            if (component.component) {
                component.component['hidden'] = hidden;
            }

            if (
                component.materialComponent &&
                component.materialComponent.element
            ) {
                component.materialComponent.element.nativeElement.hidden =
                    hidden;
                component.materialComponent.element.nativeElement.style.visibility =
                    hidden ? 'hidden' : 'visible';
                component.materialComponent.element.nativeElement.style.position =
                    hidden ? 'absolute' : '';
            }
        }
    }

    public updateFormioComponentProperty(
        rootComponents,
        componentKey,
        propertyTitle,
        propertyValue
    ) {
        const component = this.getFormioComponentByKey(
            rootComponents,
            componentKey
        );

        if (component) {
            component.component[propertyTitle] = propertyValue;
            component[propertyTitle] = propertyValue;
            component.originalComponent[propertyTitle] = propertyValue;
            component.triggerRedraw();
        }
    }

    public getSideBarConfig(id: number): Promise<any> {
        // Reset if id of project changed
        if (this.currentProjectId != id) {
            this.sideBarConfig = [];
            this.currentProjectId = id;
        }

        return new Promise(async (resolve) => {
            const promises = [];
            const config = [];

            if (!id) {
                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.BASE'
                    ),
                    Link: '/new-project',
                    Icon: 'star',
                });
            } else {
                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.BASE'
                    ),
                    Link: '/projects/detail/basedata/' + id,
                    Icon: 'star',
                });

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.DOCS'
                    ),
                    Link: '/projects/detail/documents/' + id,
                    Icon: 'folder',
                });

                // Get number of documents
                this.getNumberOfItemsWithoutFolders(
                    'Auftragsdokumente',
                    id + '',
                    new CamlBuilder()
                        .Where()
                        .LookupField('Auftrag')
                        .Id()
                        .EqualTo(id)
                        .And()
                        .NumberField('FSObjType')
                        .EqualTo(0)
                        .OrderBy('Modified')
                        .ToString()
                )
                    .then((length) => {
                        const index = config.findIndex(
                            (item) =>
                                item.Text ==
                                this._translateService.instant(
                                    'APPLICATION_SERVICE.DOCS'
                                )
                        );
                        config[index].Text =
                            this._translateService.instant(
                                'APPLICATION_SERVICE.DOCS'
                            ) +
                            ' (' +
                            length +
                            ')';
                    })
                    .catch((error) => {
                        console.error(error);
                    });

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.INVOLVED'
                    ),
                    Link: '/projects/detail/involved/' + id,
                    Icon: 'person',
                });

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.TASKS'
                    ),
                    Link: '/projects/detail/tasks/' + id,
                    Icon: 'assignment_turned_in',
                });

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.TIMELINE'
                    ),
                    Link: '/projects/detail/timeline/' + id,
                    Icon: 'schedule',
                });

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.NOTES'
                    ),
                    Link: '/projects/detail/notes/' + id,
                    Icon: 'notes',
                });

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.COMMENTS'
                    ),
                    Link: '/projects/detail/comments/' + id,
                    Icon: 'comment',
                });

                promises.push(
                    await new Promise<void>((resolve, reject) => {
                        this._updateService
                            .getPropertyValue('Zeiterfassung')
                            .then((value) => {
                                if (value == 'Ja') {
                                    config.push({
                                        Text: this._translateService.instant(
                                            'APPLICATION_SERVICE.TIMES'
                                        ),
                                        Link: '/projects/detail/times/' + id,
                                        Icon: 'watch',
                                    });
                                    this.timesEnabled = true;
                                }
                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );

                promises.push(
                    await new Promise<void>((resolve, reject) => {
                        this._updateService
                            .getPropertyValue('E-Mail Kommunikation')
                            .then((value) => {
                                if (value == 'Ja') {
                                    config.push({
                                        Text: this._translateService.instant(
                                            'APPLICATION_SERVICE.MAILS'
                                        ),
                                        Link: '/projects/detail/mails/' + id,
                                        Icon: 'mailbox',
                                    });
                                }
                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.INVOICES'
                    ),
                    Link: '/projects/detail/invoices/' + id,
                    Icon: 'assignment_turned_in',
                });

                config.push({
                    Text: this._translateService.instant(
                        'APPLICATION_SERVICE.COSTROLES'
                    ),
                    Link: '/projects/detail/costRoles/' + id,
                    Icon: 'attach_money',
                });
            }

            Promise.all(promises)
                .then(() => {
                    this.sideBarConfig = config;
                    resolve(config);
                })
                .catch((error) => {
                    this.sideBarConfig = config;
                    console.error(error);
                    resolve(config);
                });
        });
    }

    public convertMonthValue(month: string | number): string | number {
        if (typeof month == 'string') {
            switch (month) {
                case 'Januar':
                    return 1;

                case 'Februar':
                    return 2;

                case 'März':
                    return 3;

                case 'April':
                    return 4;

                case 'Mai':
                    return 5;

                case 'Juni':
                    return 6;

                case 'Juli':
                    return 7;

                case 'August':
                    return 8;

                case 'September':
                    return 9;

                case 'Oktober':
                    return 10;

                case 'November':
                    return 11;

                case 'Dezember':
                    return 12;
            }
        } else {
            switch (month) {
                case 1:
                    return 'Januar';

                case 2:
                    return 'Februar';

                case 3:
                    return 'März';

                case 4:
                    return 'April';

                case 5:
                    return 'Mai';

                case 6:
                    return 'Juni';

                case 7:
                    return 'Juli';

                case 8:
                    return 'August';

                case 9:
                    return 'September';

                case 10:
                    return 'Oktober';

                case 11:
                    return 'November';

                case 12:
                    return 'Dezember';
            }
        }
    }

    public getNumberOfItemsWithoutFolders(
        listName: string,
        folderName: string,
        camlQuery?: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!camlQuery) {
                camlQuery = new CamlBuilder()
                    .Where()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderBy('Modified')
                    .ToString();
            }

            // Get number of items
            this._sharepointService
                .getListItems({
                    title: listName,
                    isDocumentLibrary: true,
                    recursiveAll: true,
                    folderName,
                    camlQuery,
                })
                .then((items) => {
                    resolve(items.length);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public updateAssignemntPermissions(assignmentItem): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            // Get SPItem
            const assignmentSPItem = this._sharepointService.web.lists
                .getByTitle('Aufträge')
                .items.getById(assignmentItem.Id);

            promises.push(
                this.updateItemPermissions(assignmentItem, assignmentSPItem)
            );

            for (const dependantList of this.dependantFolderLists) {
                promises.push(
                    this.updateFolderPermissions(assignmentItem, dependantList)
                );
            }

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public updateFolderPermissions(
        assignmentItem,
        dependantList
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // Get folderItemId
            this._updateService
                .listExists(dependantList.listTitle)
                .then((exists) => {
                    if (exists) {
                        let url =
                            this.computeServerRelativeUrl(
                                this._sharepointService.sphostUrl
                            ) +
                            '/Lists/' +
                            dependantList.listTitle +
                            '/' +
                            assignmentItem.Id;

                        if (dependantList.isDocumentLibrary) {
                            url =
                                this.computeServerRelativeUrl(
                                    this._sharepointService.sphostUrl
                                ) +
                                '/' +
                                dependantList.listTitle +
                                '/' +
                                assignmentItem.Id;
                        }

                        this.loadItemFromFolder(url)
                            .then((item) => {
                                // Get folderSPItem
                                const folderItem =
                                    this._sharepointService.web.lists
                                        .getByTitle(dependantList.listTitle)
                                        .items.getById(item.Id);

                                this.updateItemPermissions(
                                    assignmentItem,
                                    folderItem
                                )
                                    .then(() => {
                                        // Success
                                        resolve();
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        // List not existing
                        resolve();
                    }
                });
        });
    }

    public updateItemPermissions(
        assignmentItem: any,
        itemToUpdate: IItem
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            let readerIds = [];
            let editorIds = [];

            if (assignmentItem.LeseberechtigungId) {
                if (assignmentItem.LeseberechtigungId.results) {
                    readerIds = assignmentItem.LeseberechtigungId.results;
                } else {
                    readerIds = assignmentItem.LeseberechtigungId;
                }
            }

            if (assignmentItem.BearbeitungsberechtigungId) {
                if (assignmentItem.BearbeitungsberechtigungId.results) {
                    editorIds =
                        assignmentItem.BearbeitungsberechtigungId.results;
                } else {
                    editorIds = assignmentItem.BearbeitungsberechtigungId;
                }
            }

            itemToUpdate
                .breakRoleInheritance(true)
                .then(() => {
                    this.removePermissionsFromItem(itemToUpdate)
                        .then(() => {
                            this.setPermissionsForItem(
                                itemToUpdate,
                                editorIds,
                                5
                            )
                                .then(() => {
                                    // Success
                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public removePermissionsFromItem(item: IItem): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // Set separate admin permission for currentUser
            this.setPermissionsForItem(
                item,
                [this._sharepointService.currentUser.Id],
                5
            )
                .then(() => {
                    item.roleAssignments
                        .expand('RoleDefinitionBindings')()
                        .then((permissions: any) => {
                            const promises = [];

                            permissions.forEach((permission) => {
                                let bindings =
                                    permission.RoleDefinitionBindings;

                                if (bindings.results) {
                                    bindings = bindings.results;
                                }
                                bindings.forEach((binding) => {
                                    if (
                                        permission.PrincipalId !=
                                        this._sharepointService.currentUser.Id
                                    ) {
                                        promises.push(
                                            item.roleAssignments.remove(
                                                permission.PrincipalId,
                                                binding.Id
                                            )
                                        );
                                    } else if (binding.RoleTypeKind != 5) {
                                        promises.push(
                                            item.roleAssignments.remove(
                                                permission.PrincipalId,
                                                binding.Id
                                            )
                                        );
                                    }
                                });
                            });

                            Promise.all(promises)
                                .then(() => {
                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public setPermissionsForItem(
        item: IItem,
        userIds: number[],
        roleId: RoleTypeKind
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // Set Permissions one by one
            this._sharepointService.web.roleDefinitions
                .getByType(roleId)()
                .then((rd) => {
                    const promises = [];

                    userIds.forEach((id) => {
                        promises.push(item.roleAssignments.add(id, rd.Id));
                    });

                    Promise.all(promises)
                        .then(() => {
                            console.log('Permission setted');
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public addFoldersForAssignment(assignmentItem): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            for (const dependantList of this.dependantFolderLists) {
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._updateService
                            .listExists(dependantList.listTitle)
                            .then((exists) => {
                                if (exists) {
                                    this._sharepointService
                                        .addFolder({
                                            listTitle: dependantList.listTitle,
                                            folderName: assignmentItem.Id + '',
                                            isDocumentLibrary:
                                                dependantList.isDocumentLibrary,
                                        })
                                        .then(() => resolve())
                                        .catch((error) => {
                                            reject(error);
                                        });
                                } else {
                                    resolve();
                                }
                            });
                    })
                );
            }

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public computeServerRelativeUrl(spHostUrl?: string): string {
        spHostUrl = spHostUrl
            ? spHostUrl
            : decodeURIComponent(this._sharepointService.sphostUrl);
        const arr: string[] = spHostUrl.split('/');
        const tmp: string = arr[0] + '//' + arr[2];

        const result: string = spHostUrl.replace(tmp, '');

        return result;
    }

    public leftMerge(a, b) {
        const res = {};
        // tslint:disable-next-line: forin
        for (const p in a) {
            res[p] = (p in b ? b : a)[p];

            if (p.endsWith('Id') && Array.isArray(res[p])) {
                res[p] = { results: res[p] };
            }
        }
        return res;
    }

    public filterData(data, type) {
        const r = data.filter((o) => {
            return o.type === type;
        });
        return r;
    }

    public getEmptyObject(components, obj) {
        components.forEach((element) => {
            if (!element.components) {
                if (
                    element.type !== 'button' &&
                    element.type !== 'htmlelement' &&
                    element.type !== 'columns' &&
                    element.type !== 'column'
                ) {
                    if (
                        element.type === 'sharePointAutocomplete' &&
                        element.multi
                    ) {
                        obj[element.key] = {
                            results: [],
                        };
                    } else {
                        obj[element.key] = null;
                    }
                }
                if (element.type === 'columns') {
                    element.columns.forEach((column) => {
                        this.getEmptyObject(column.components, obj);
                    });
                }
            } else {
                this.getEmptyObject(element.components, obj);
            }
        });
    }

    public removeEmtpyStringAndSetNull(data) {
        for (const propertyName in data) {
            if (propertyName == null || propertyName == '') {
                delete data[propertyName];
            } else if (data[propertyName] === '') {
                data[propertyName] = null;
            }
        }
    }

    public cleanDataForSaving(data, components) {
        this.removeEmtpyStringAndSetNull(data);

        // Datumswerte umwandeln in Date Objekte
        this.filterData(components, 'datetime').forEach((element) => {
            if (data[element.key]) {
                data[element.key] = new Date(data[element.key]);
            } else {
                data[element.key] = null;
            }
        });

        const emptyObj = {};
        this.getEmptyObject(components, emptyObj);
        this.leftMerge(emptyObj, data);
    }

    public getSiteGroupByName(
        name: string
    ): Promise<[{ Id: number; Title: string; LoginName: string }]> {
        return this._sharepointService.web.siteGroups
            .getByName(name)
            .select('Id', 'Title')<
            [{ Id: number; Title: string; LoginName: string }]
        >();
    }

    public copyCategoryDocuments(
        categoryId,
        auftragId,
        confirmMessage
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // Get categorydocuments
            this._sharepointService
                .getListItems({
                    title: 'Kategoriedokumente',
                    isDocumentLibrary: true,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField('Kategorie')
                        .Id()
                        .EqualTo(categoryId)
                        .ToString(),
                })
                .then((docs) => {
                    if (docs.length > 0) {
                        this._loadingService.hide();

                        this.confirmDialogRef = this.dialog.open(
                            ConfirmDialogTasksComponent,
                            {
                                data: {
                                    confirmMessage,
                                },
                            }
                        );

                        this.confirmDialogRef
                            .afterClosed()
                            .subscribe((result) => {
                                // Add Loading
                                if (result) {
                                    const promises = [];

                                    docs.forEach((element) => {
                                        promises.push(
                                            new Promise<void>(
                                                (resolve, reject) => {
                                                    const targetPath =
                                                        'Auftragsdokumente/' +
                                                        auftragId +
                                                        '/' +
                                                        element
                                                            .FieldValuesAsText
                                                            .FileLeafRef;

                                                    this._sharepointService.web
                                                        .using(
                                                            InjectHeaders({
                                                                Accept: 'application/json;odata=fullmetadata',
                                                                'Content-Type':
                                                                    'application/json;charset=utf-8',
                                                            })
                                                        )
                                                        .getFileByServerRelativePath(
                                                            element.FileRef
                                                        )
                                                        .copyTo(
                                                            targetPath,
                                                            true
                                                        )
                                                        .then(() => {
                                                            this.loadItemFromFile(
                                                                this.computeServerRelativeUrl(
                                                                    this
                                                                        ._sharepointService
                                                                        .sphostUrl
                                                                ) +
                                                                    '/' +
                                                                    targetPath
                                                            )
                                                                .then(
                                                                    (data) => {
                                                                        // Update Item
                                                                        this._sharepointService
                                                                            .updateItem(
                                                                                {
                                                                                    listTitle:
                                                                                        'Auftragsdokumente',
                                                                                    id: data
                                                                                        .item
                                                                                        .Id,
                                                                                    newFiledValues:
                                                                                        {
                                                                                            AuftragId:
                                                                                                auftragId,
                                                                                        },
                                                                                }
                                                                            )
                                                                            .then(
                                                                                () => {
                                                                                    // Success
                                                                                    resolve();
                                                                                }
                                                                            )
                                                                            .catch(
                                                                                (
                                                                                    error
                                                                                ) => {
                                                                                    reject(
                                                                                        error
                                                                                    );
                                                                                }
                                                                            );
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) => {
                                                                        reject(
                                                                            error
                                                                        );
                                                                    }
                                                                );
                                                        })
                                                        .catch((error) => {
                                                            reject(error);
                                                        });
                                                }
                                            )
                                        );
                                    });

                                    Promise.all(promises)
                                        .then(() => {
                                            // Success
                                            resolve();
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                } else {
                                    resolve();
                                }
                            });
                    } else {
                        // Add Loading
                        resolve();
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public loadItemFromFile(fileServerRelativeUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const file =
                this._sharepointService.web.getFileByServerRelativePath(
                    fileServerRelativeUrl
                );

            file.listItemAllFields()
                .then((item) => {
                    resolve({ item });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public loadItemFromFolder(folderServerRelativeUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const folder =
                this._sharepointService.web.getFolderByServerRelativePath(
                    folderServerRelativeUrl
                );

            folder
                .listItemAllFields()
                .then((item) => {
                    resolve(item);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getDefaultPermissionsForProject(): Promise<any> {
        return new Promise<void>(async (resolve, reject) => {
            const list: IList =
                this._sharepointService.web.lists.getByTitle('Aufträge');

            this.editorPermissions = [];
            this.readerPermissions = [];

            this._updateService
                .getPropertyValue(
                    'Standard Gruppen für Bearbeitungsberechtigung'
                )
                .then((value) => {
                    if (value && value.split(';').length > 0) {
                        // Get default permission groups from config
                        value.split(';').forEach((groupName) => {
                            const promises = [];
                            promises.push(
                                new Promise<void>(async (resolve, reject) => {
                                    this.getSiteGroupByName(groupName)
                                        .then((group: any) => {
                                            this.editorPermissions.push(
                                                group.Id
                                            );

                                            resolve();
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                            );

                            Promise.all(promises)
                                .then(() => {
                                    // Success
                                    resolve();
                                })
                                .catch((error) => {
                                    // Snackbar Hinweis nicht alle Standardberechtigungen gefunden?
                                    console.error(error);
                                    reject(error);
                                });
                        });
                    } else {
                        // Get default permission groups from list
                        list.roleAssignments
                            .expand('Member, RoleDefinitionBindings')()
                            .then((permissions: any) => {
                                let currentUserIsInGroup = false;
                                const promises = [];
                                permissions.forEach((permission) => {
                                    let bindings =
                                        permission.RoleDefinitionBindings;

                                    if (bindings.results) {
                                        bindings = bindings.results;
                                    }

                                    bindings.forEach(
                                        (roledefinitionbinding) => {
                                            if (
                                                roledefinitionbinding.RoleTypeKind ==
                                                    5 ||
                                                roledefinitionbinding.RoleTypeKind ==
                                                    6
                                            ) {
                                                this.editorPermissions.push(
                                                    permission.PrincipalId
                                                );
                                            } else if (
                                                roledefinitionbinding.RoleTypeKind ==
                                                2
                                            ) {
                                                this.readerPermissions.push(
                                                    permission.PrincipalId
                                                );
                                            }
                                        }
                                    );

                                    if (
                                        permission.Member['odata.type'] ==
                                        'SP.Group'
                                    ) {
                                        promises.push(
                                            new Promise<void>(
                                                (resolve, reject) => {
                                                    this.isCurrentUserInGroup(
                                                        permission.Member.Title
                                                    )
                                                        .then((result) => {
                                                            if (result) {
                                                                currentUserIsInGroup =
                                                                    true;
                                                            }
                                                            resolve();
                                                        })
                                                        .catch((err) => {
                                                            reject(err);
                                                        });
                                                }
                                            )
                                        );
                                    }
                                });
                                Promise.all(promises)
                                    .then(() => {
                                        if (!currentUserIsInGroup) {
                                            this.editorPermissions.push(
                                                this._sharepointService
                                                    .currentUser.Id
                                            );
                                        }
                                        resolve();
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                })
                .catch((error) => {
                    reject(error);
                    console.error(error);
                });
        });
    }

    /**
     * Ruft alle Listenelemente rekursiv mit Pagination ab, optional einschließlich oder ausschließlich Ordner.
     * @param {string} listTitle Der Titel der SharePoint-Liste
     * @param {boolean} includeFolders Bestimmt, ob Ordner in die Ergebnisse eingeschlossen werden sollen
     * @returns {Promise<any[]>} Ein Promise, das bei Erfolg ein Array von Listenelementen zurückgibt
     */
    async getAllItemsPaged(listTitle, includeFolders = false) {
        let allItems = [];
        let items = await this._sharepointService.web.lists
            .getByTitle(listTitle)
            .items.top(1000) // kleinere Batch-Größe, z.B. 1000 statt 5000
            .getPaged();

        while (items) {
            allItems = allItems.concat(items.results);
            if (items.hasNext) {
                await new Promise((res) => setTimeout(res, 500)); // 500ms Pause einfügen
                items = await items.getNext();
            } else {
                break;
            }
        }

        return includeFolders
            ? allItems
            : allItems.filter(
                  (item) =>
                      item.FSObjType !== 1 && item.FileSystemObjectType !== 1
              );
    }

    async getFilteredItemsPaged(
        listTitle: string,
        camlQuery: string
    ): Promise<any[]> {
        let allItems = [];
        let items = await this._sharepointService.web.lists
            .getByTitle(listTitle)
            .getItemsByCAMLQuery(this.getCamlQueryObject(camlQuery));

        allItems = allItems.concat(items);

        while (items.hasNext) {
            await new Promise((res) => setTimeout(res, 500)); // kleine Pause, um Überlastung zu vermeiden
            items = await items.getNext();
            allItems = allItems.concat(items);
        }

        return allItems;
    }

    getCamlQueryObject(camlQuery: string): ICamlQuery {
        // Create CamlQuery object with RowLimit of 1000 and recursiveAll to include all folders
        const camlQueryObject: ICamlQuery = {
            ViewXml: `<View Scope='RecursiveAll'><Query>${camlQuery}</Query><RowLimit>1000</RowLimit></View>`,
        };

        return camlQueryObject;
    }

    public addFoldersForExistingProjects(
        listTitle: string,
        isDocumentLibrary: boolean
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.getAllItemsPaged('Aufträge')
                .then((items) => {
                    const promises = [];

                    items.forEach((item) => {
                        // Add folders
                        promises.push(
                            new Promise<void>((resolve, reject) => {
                                this._sharepointService
                                    .addFolder({
                                        listTitle,
                                        folderName: item.Id + '',
                                        isDocumentLibrary,
                                    })
                                    .then(() => {
                                        this.updateFolderPermissions(item, {
                                            listTitle,
                                            isDocumentLibrary,
                                        })
                                            .then(() => resolve())
                                            .catch((error) => reject(error));
                                    })
                                    .catch((error) => reject(error));
                            })
                        );
                    });

                    Promise.all(promises)
                        .then(() => resolve())
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    public isCurrentUserInGroup(name: string) {
        return new Promise((resolve, reject) => {
            this._sharepointService.web.currentUser
                .groups()
                .then((groups) => {
                    resolve(groups.some((e) => e.LoginName === name));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public isCurrentUserInGroupWithId(id: number) {
        return new Promise((resolve, reject) => {
            this._sharepointService.web.currentUser
                .groups()
                .then((groups) => {
                    resolve(groups.some((e) => e.Id === id));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    createNewProject(
        data: any,
        isAssignment?: boolean,
        budgetTable?: any[]
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            // Projektnummer setzen
            this.getProjectNumber(data.FachbereichId)
                .then((projectNumber) => {
                    data.Projektnummer = projectNumber;

                    this._sharepointService
                        .addItem({
                            listTitle: 'Aufträge',
                            data: data,
                            folder: '',
                        })
                        .then(async (newItemId) => {
                            if (isAssignment) {
                                await this._assignmentBudgetService.initAssignementWithBudget(
                                    newItemId,
                                    budgetTable
                                );
                            }

                            this._sharepointService
                                .getItemById({
                                    listTitle: 'Aufträge',
                                    id: +newItemId,
                                })
                                .then((item) => {
                                    // Add folders
                                    this.addFoldersForAssignment(item)
                                        .then(() => {
                                            const promises = [];

                                            // Set Permissions
                                            promises.push(
                                                this.updateAssignemntPermissions(
                                                    item
                                                )
                                            );

                                            if (item.AuftragskategorieId) {
                                                // Copy docs from category
                                                promises.push(
                                                    this.copyCategoryDocuments(
                                                        item.AuftragskategorieId,
                                                        item.Id,
                                                        this._translateService.instant(
                                                            'TASK_SERVICE.NOTIFICATIONS.COPYCATEGORYDOCS'
                                                        )
                                                    )
                                                );
                                            }

                                            Promise.all(promises)
                                                .then(() => {
                                                    resolve(item);
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getProjectNumber(fachbereichId: number): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const promises = [];

            let bereichsnummer = '000';
            let currentProjectNumber = '00000';

            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService
                        .getListItems({
                            title: 'Properties',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .View()
                                .RowLimit(1)
                                .Query()
                                .Where()
                                .TextField('Title')
                                .Contains('Aktuelle Projektnummer')
                                .ToString(),
                        })
                        .then((items) => {
                            let elementId = -1;
                            let nextValue = '00000';

                            // Get current projectnumber
                            items.forEach((element) => {
                                currentProjectNumber = element.Value;
                                elementId = element.Id;
                            });

                            // Calculate next projectNumber
                            nextValue = (+currentProjectNumber + 1)
                                .toString()
                                .padStart(5, '0');

                            // Update current projectnumber in config
                            this._sharepointService
                                .updateItem({
                                    id: elementId,
                                    listTitle: 'Properties',
                                    newFiledValues: {
                                        Value: nextValue,
                                    },
                                })
                                .then(() => {
                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService
                        .getItemById({
                            listTitle: 'Fachbereiche',
                            id: fachbereichId,
                        })
                        .then((item) => {
                            // Get bereichsnummer
                            bereichsnummer = item.Bereichsnummer;
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            Promise.all(promises)
                .then(() =>
                    resolve(bereichsnummer + '-' + currentProjectNumber)
                )
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getInvoiceNumber(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const promises = [];

            // let bereichsnummer = '000';
            let currentInvoiceNumber = '0';

            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService
                        .getListItems({
                            title: 'Properties',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .View()
                                .RowLimit(1)
                                .Query()
                                .Where()
                                .TextField('Title')
                                .Contains('Aktuelle Rechnungsnummer')
                                .ToString(),
                        })
                        .then((items) => {
                            let elementId = -1;
                            let nextValue = '0';

                            // Get current projectnumber
                            items.forEach((element) => {
                                currentInvoiceNumber = element.Value;
                                elementId = element.Id;
                            });

                            // Calculate next projectNumber
                            nextValue = (+currentInvoiceNumber + 1).toString();
                            // .padStart(5, '0');

                            // Update current projectnumber in config
                            this._sharepointService
                                .updateItem({
                                    id: elementId,
                                    listTitle: 'Properties',
                                    newFiledValues: {
                                        Value: nextValue,
                                    },
                                })
                                .then(() => {
                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            Promise.all(promises)
                .then(() =>
                    resolve(
                        new Date().getFullYear() + '-' + currentInvoiceNumber
                    )
                )
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getARNumber(projectId: number, isAR: boolean): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const promises = [];

            // let bereichsnummer = '000';
            let currentARNumber = 0;

            if (isAR) {
                promises.push(
                    new Promise<void>(async (resolve, reject) => {
                        this._sharepointService
                            .getItemById({
                                listTitle: 'Aufträge',
                                id: projectId,
                            })
                            .then((item) => {
                                let nextValue = 0;

                                // Get current ARNumber
                                if (item.currentAR_Number) {
                                    currentARNumber = item.currentAR_Number;
                                } else {
                                    currentARNumber = 1;
                                }

                                // Calculate next ARNumber
                                nextValue = currentARNumber + 1;

                                // Update current ARNumber
                                this._sharepointService
                                    .updateItem({
                                        id: projectId,
                                        listTitle: 'Aufträge',
                                        newFiledValues: {
                                            currentAR_Number: nextValue,
                                        },
                                    })
                                    .then(() => {
                                        resolve();
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );
            }

            Promise.all(promises)
                .then(() => resolve(currentARNumber))
                .catch((error) => {
                    reject(error);
                });
        });
    }

    callApiWithAuthNew(
        endpointUrl: string,
        params: { Name: string; Value: string }[],
        file?: File,
        responseType?: string
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            // Add File if exists
            let formData: FormData = new FormData();

            if (file) {
                formData.append('file', file);
                formData.append('fileName;' + file.name, '');
            }

            // Add Params
            formData.append(
                'webUrl;' +
                    encodeURIComponent(this._sharepointService.sphostUrl),
                ''
            );

            // formData.append(
            //     'isProd;' +
            //         encodeURIComponent(environment.production.toString()),
            //     ''
            // );

            params.forEach((param) => {
                formData.append(
                    param.Name + ';' + encodeURIComponent(param.Value),
                    ''
                );
            });

            // Add headers
            let headers = new HttpHeaders();

            // Get MSAL token
            // const client = new MsalClient({
            //     auth: {
            // authority: 'https://login.microsoftonline.com/common',
            // clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
            // redirectUri: location.origin,
            //     },
            // });

            const userAgenApplication = new UserAgentApplication({
                auth: {
                    authority: 'https://login.microsoftonline.com/common',
                    clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
                    redirectUri: location.origin,
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
                framework: {
                    isAngular: true,
                },
            });

            promises.push(
                new Promise<void>((resolve, reject) => {
                    // client
                    //     .getToken([this.serverUrl + '/AllSites.FullControl'])
                    userAgenApplication
                        .acquireTokenSilent({
                            scopes: [this.serverUrl + '/AllSites.FullControl'],
                        })
                        .then((token) => {
                            // Add token to headers
                            headers = headers.append(
                                'Authorization',
                                `Bearer ${token.accessToken}`
                            );

                            resolve();
                        })
                        .catch((error) => reject(error));
                })
            );

            Promise.all(promises)
                .then(() => {
                    if (responseType == 'arraybuffer') {
                        this.http
                            .post(endpointUrl, formData, {
                                headers: headers,
                                responseType: 'arraybuffer',
                            })
                            .toPromise()
                            .then((result: any) => {
                                // Success
                                resolve(result);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        this.http
                            .post(endpointUrl, formData, {
                                headers: headers,
                                // responseType: 'arraybuffer',
                            })
                            .toPromise()
                            .then((result: any) => {
                                // Success
                                resolve(result);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public callApiWithAuth(
        endpointUrl: string,
        params: { Name: string; Value: string }[],
        requestObj: any,
        responseType?: string
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            // Add File if exists
            //let formData: FormData = new FormData();

            //if (file) {
            //   formData.append(file.name, file);
            //}

            // Add headers
            let headers = new HttpHeaders();

            // Add Params
            /*headers = headers.append(
                'webUrl',
                encodeURIComponent(this._sharepointService.sphostUrl)
            );
            headers = headers.append(
                'isProd',
                encodeURIComponent(environment.production.toString())
            );

            params.forEach((param) => {
                headers = headers.append(
                    param.Name,
                    encodeURIComponent(param.Value)
                );
            });*/

            // Get MSAL token
            if (!window['useApp']) {
                // MSAL
                // const client = new MsalClient({
                //     auth: {
                //         authority: 'https://login.microsoftonline.com/common',
                //         clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
                //         redirectUri: location.origin,
                //     },
                // });

                const userAgenApplication = new UserAgentApplication({
                    auth: {
                        authority: 'https://login.microsoftonline.com/common',
                        clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
                        redirectUri: location.origin,
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                    },
                    framework: {
                        isAngular: true,
                    },
                });

                promises.push(
                    new Promise<void>((resolve, reject) => {
                        // client
                        //     .getToken([
                        // this.serverUrl + '/AllSites.FullControl',
                        // 'Mail.Read',
                        //     ])
                        userAgenApplication
                            .acquireTokenSilent({
                                scopes: [
                                    this.serverUrl + '/AllSites.FullControl',
                                    'Mail.Read',
                                ],
                            })
                            .then((token) => {
                                // Add token to headers
                                /*headers = headers.append(
                                    'Authorization',
                                    `Bearer ${token}`
                                );*/
                                requestObj.token = `Bearer ${token.accessToken}`;
                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                );
            }
            Promise.all(promises)
                .then(() => {
                    const body = JSON.stringify(requestObj);
                    if (responseType == 'arraybuffer') {
                        this.http
                            .post(endpointUrl, body, {
                                headers: headers,
                                responseType: 'arraybuffer',
                            })
                            .toPromise()
                            .then((result: any) => {
                                // Success
                                resolve(result);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        this.http
                            .post(endpointUrl, body, {
                                headers: headers,
                            })
                            .toPromise()
                            .then((result: any) => {
                                // Success
                                resolve(result);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public callGraphApiWithAuth(
        endpointUrl: string,
        params: { Name: string; Value: string }[],
        requestObj: any,
        responseType?: string
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            // Add headers
            let headers = new HttpHeaders();

            // Get MSAL token
            if (!window['useApp']) {
                // MSAL
                // const client = new MsalClient({
                //     auth: {
                // authority: 'https://login.microsoftonline.com/common',
                // clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
                // redirectUri: location.origin,
                //     },
                // });

                const userAgenApplication = new UserAgentApplication({
                    auth: {
                        authority: 'https://login.microsoftonline.com/common',
                        clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
                        redirectUri: location.origin,
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                    },
                    framework: {
                        isAngular: true,
                    },
                });

                promises.push(
                    new Promise<void>((resolve, reject) => {
                        // client
                        //     .getToken(['Mail.Read'])
                        userAgenApplication
                            .acquireTokenSilent({
                                scopes: ['Mail.Read'],
                            })
                            .then((token) => {
                                // Add token to headers
                                headers = headers.append(
                                    'Authorization',
                                    `Bearer ${token.accessToken}`
                                );
                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                );
            }
            Promise.all(promises)
                .then(() => {
                    const body = JSON.stringify(requestObj);
                    if (responseType == 'arraybuffer') {
                        this.http
                            .post(endpointUrl, body, {
                                headers: headers,
                                responseType: 'arraybuffer',
                            })
                            .toPromise()
                            .then((result: any) => {
                                // Success
                                resolve(result);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        this.http
                            .get(endpointUrl, {
                                headers: headers,
                            })
                            .toPromise()
                            .then((result: any) => {
                                // Success
                                resolve(result);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getChildMailfoldersById(
        folderId: string,
        top?: number
    ): Promise<any[]> {
        return new Promise((resolve, reject) => {
            const promises = [];
            if (!top) {
                top = 100;
            }
            let endpointUrl =
                "https://graph.microsoft.com/v1.0/me/mailFolders('" +
                folderId +
                "')/childFolders?&top=" +
                top;

            // Add headers
            let headers = new HttpHeaders();

            // Get MSAL token
            if (!window['useApp']) {
                // MSAL
                // const client = new MsalClient({
                //     auth: {
                // authority: 'https://login.microsoftonline.com/common',
                // clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
                // redirectUri: location.origin,
                //     },
                // });

                const userAgenApplication = new UserAgentApplication({
                    auth: {
                        authority: 'https://login.microsoftonline.com/common',
                        clientId: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // sharepoint-template-Azure-App
                        redirectUri: location.origin,
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                    },
                    framework: {
                        isAngular: true,
                    },
                });

                promises.push(
                    new Promise<void>((resolve, reject) => {
                        // client
                        //     .getToken(['Mail.Read'])
                        userAgenApplication
                            .acquireTokenSilent({
                                scopes: ['Mail.Read'],
                            })
                            .then((token) => {
                                // Add token to headers
                                headers = headers.append(
                                    'Authorization',
                                    `Bearer ${token.accessToken}`
                                );
                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                );
            }
            Promise.all(promises)
                .then(() => {
                    this.http
                        .get(endpointUrl, {
                            headers: headers,
                        })
                        .toPromise()
                        .then((result: any) => {
                            // Success
                            let cFolders = [];
                            result.value.forEach((e) => {
                                cFolders.push({
                                    id: e.id,
                                    displayName: e.displayName,
                                    parentFolderId: e.parentFolderId,
                                    childFolderCount: e.childFolderCount,
                                });
                            });

                            resolve(cFolders);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    searchUsers(options: {
        value: string;
        searchGroups?: string[];
        groups?: boolean;
    }): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (options.searchGroups && options.searchGroups.length > 0) {
                // Get users of each searchGroup
                const promises = [];
                const groupUser = [];

                options.searchGroups.forEach((groupName) => {
                    promises.push(
                        new Promise<void>((resolve, reject) => {
                            this._sharepointService.web.siteGroups
                                .getByName(groupName)
                                .users()
                                .then((users) => {
                                    users.forEach((user) => {
                                        if (options.value) {
                                            if (
                                                options.value
                                                    .toLocaleLowerCase()
                                                    .split(' ')
                                                    .every((x) =>
                                                        user.Title.toLocaleLowerCase().includes(
                                                            x
                                                        )
                                                    )
                                            ) {
                                                if (
                                                    !groupUser.some(
                                                        (x) => x.Id == user.Id
                                                    )
                                                ) {
                                                    groupUser.push(user);
                                                }
                                            }
                                        } else {
                                            if (
                                                !groupUser.some(
                                                    (x) => x.Id == user.Id
                                                )
                                            ) {
                                                groupUser.push(user);
                                            }
                                        }
                                    });

                                    resolve();
                                })
                                .catch((error) => reject(error));
                        })
                    );
                });

                Promise.all(promises)
                    .then(() => {
                        resolve(groupUser);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else if (options.value) {
                let users = await this._frameworkService.searchSiteUsers(
                    options.value
                );

                // Rename properties
                users.forEach(function (data) {
                    if (data['userPrincipalName']) {
                        data['LoginName'] = data['userPrincipalName'];
                    }

                    if (data['displayName']) {
                        data['Title'] = data['displayName'];
                    }
                });

                resolve(users);
            } else {
                resolve([]);
            }
        });
    }

    async isUserInRole(role: string): Promise<boolean> {
        try {
            const currentUser = this._sharepointService.currentUser;
            if (
                currentUser.IsAdministrator ||
                currentUser.IsBusinessOwner ||
                this._currentUserService.isTeamProjectLeiter()
            ) {
                return true;
            }

            const permissionGroupsId =
                await this._sharepointService.getListItems({
                    title: 'Berechtigungsgruppen',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .TextField('Title')
                        .EqualTo(role)
                        .ToString(),
                    viewFields: [
                        {
                            internalName: 'Berechtigungsgruppe',
                        },
                    ],
                });

            if (permissionGroupsId.length) {
                let groupIds = permissionGroupsId[0].BerechtigungsgruppeId;

                if (
                    permissionGroupsId[0].BerechtigungsgruppeId &&
                    permissionGroupsId[0].BerechtigungsgruppeId.results
                ) {
                    groupIds =
                        permissionGroupsId[0].BerechtigungsgruppeId.results;
                }

                if (groupIds) {
                    for (let index = 0; index < groupIds.length; index++) {
                        const groupId = groupIds[index];

                        const users =
                            await this._sharepointService.web.siteGroups
                                .getById(groupId)
                                .users();
                        for (let user of users) {
                            //If user is in a permissiongroup, this method has no job left
                            if (user.Id == currentUser.Id) {
                                return true;
                            }
                        }
                    }
                }
            }

            return false;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    public async ensureFolder(
        serverRelativeUrl: string,
        listTitle: string,
        rootFolder: string,
        folderName: string,
        isDocumentLibrary: boolean
    ): Promise<any> {
        const folder = await this._sharepointService.web
            .getFolderByServerRelativePath(serverRelativeUrl)
            .select('Exists')();

        if (!folder.Exists) {
            await this._sharepointService.addFolderByPath({
                listTitle,
                rootFolder,
                folderName,
                isDocumentLibrary,
            });
        }
    }
}
