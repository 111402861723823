import { Injectable } from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { from, Observable } from 'rxjs';
import { AssignmentBudget } from '../shared/models/assignment-budget.model';
import { ICamlQuery } from '@pnp/sp/lists';

@Injectable({
    providedIn: 'root',
})
export class AssignmentBudgetService {
    public assignmentInitialBudgets: any[];
    public assignmentInitialNebenkostenEuro = 0;
    public assignmentInitialSkontoTotal = 0;

    constructor(private _sharepointService: SharePointService) {}

    public async checkIsFolderExist(assignmentId: number): Promise<boolean> {
        try {
            await this._sharepointService.web.lists
                .getByTitle('Aufträge-Budgets')
                .rootFolder.folders.getByUrl(`${assignmentId}`)();
            return true;
        } catch (error) {
            return false;
        }
    }

    public async saveAssignmentBudget(
        assignmentId: number,
        budgetTable: {
            Id: number;
            TB_Antragssumme: number;
            TB_Nebenkosten: number;
            TB_Fremdleistung: number;
            TB_Nachtrag: string;
            TB_Datum: Date | string;
            TB_Notiz: string;
            TB_Skonto: boolean;
        }[]
    ) {
        const folderExist = await this.checkIsFolderExist(assignmentId);
        console.warn(
            `%cFOLDER_EXIST: ${folderExist}`,
            `color: ${folderExist ? 'green' : 'false'}`
        );

        if (!folderExist) {
            console.warn(`%cCREATING A FOLDER`, 'color: green');
            await this._sharepointService.addFolder({
                listTitle: 'Aufträge-Budgets',
                isDocumentLibrary: false,
                folderName: `${assignmentId}`,
            });
        }

        console.warn(`%cAFTER FOLDER`, 'color: green');

        for (let i = 0; i < budgetTable.length; i++) {
            const payload = {
                Antragssumme: budgetTable[i].TB_Antragssumme || 0,
                Nebenkosten: budgetTable[i].TB_Nebenkosten || 0,
                Fremdleistung: budgetTable[i].TB_Fremdleistung || 0,
                Nachtrag: budgetTable[i].TB_Nachtrag,
                Datum:
                    budgetTable[i].TB_Datum === ''
                        ? null
                        : budgetTable[i].TB_Datum,
                Skonto: budgetTable[i].TB_Skonto,
                Notiz: budgetTable[i].TB_Notiz,
                Auftr_x00e4_geId: assignmentId,
            };
            if (budgetTable[i].Id) {
                await this._sharepointService.updateItem({
                    listTitle: 'Aufträge-Budgets',
                    id: budgetTable[i].Id,
                    newFiledValues: payload,
                });
            } else {
                await this._sharepointService.addItem({
                    listTitle: 'Aufträge-Budgets',
                    folder: `${assignmentId}`,
                    data: payload,
                });
            }
            await (() =>
                new Promise((resolve) =>
                    setTimeout(() => resolve(null), 500)
                ))();
        }

        await this.loadAssignmentBudgets(assignmentId);
    }

    public async loadAssignmentBudgets(assignmentId: number): Promise<void> {
        this.assignmentInitialBudgets =
            await this._sharepointService.getListItems({
                title: 'Aufträge-Budgets',
                isDocumentLibrary: false,
                folderName: `${assignmentId}`,
                camlQuery: new CamlBuilder()
                    .Where()
                    .LookupField('Auftr_x00e4_ge')
                    .Id()
                    .EqualTo(assignmentId)
                    .ToString(),
            });

        this.assignmentInitialBudgets.forEach((item) => {
            item.TB_Antragssumme = item.Antragssumme;
            item.TB_Nebenkosten = item.Nebenkosten;
            item.TB_Fremdleistung = item.Fremdleistung;
            item.TB_Nachtrag = item.Nachtrag;
            item.TB_Datum = item.Datum;
            item.TB_Notiz = item.Notiz;
            item.TB_Skonto = item.Skonto;
            item.TB_NebenkostenEuro =
                (item.Antragssumme / 100) * (item.Nebenkosten ?? 0);
            this.assignmentInitialNebenkostenEuro =
                this.assignmentInitialNebenkostenEuro + item.TB_NebenkostenEuro;
            this.assignmentInitialSkontoTotal =
                this.assignmentInitialSkontoTotal +
                (item.TB_Skonto && item.TB_Antragssumme
                    ? (item.TB_Antragssumme + item.TB_NebenkostenEuro) * 0.03
                    : 0);
        });
    }

    public async initAssignementWithBudget(
        assignmentId: number,
        budgetTable: {
            Id: number;
            TB_Antragssumme: number;
            TB_Nebenkosten: number;
            TB_Fremdleistung: number;
            TB_Nachtrag: string;
            TB_Datum: Date;
            TB_Notiz: string;
            TB_Skonto: boolean;
        }[]
    ): Promise<void> {
        await this._sharepointService.addFolder({
            listTitle: 'Aufträge-Budgets',
            folderName: assignmentId + '',
            isDocumentLibrary: false,
        });

        for (let i = 0; i < budgetTable.length; i++) {
            const payload = {
                Antragssumme: budgetTable[i].TB_Antragssumme || 0,
                Nebenkosten: budgetTable[i].TB_Nebenkosten || 0,
                Fremdleistung: budgetTable[i].TB_Fremdleistung || 0,
                Nachtrag: budgetTable[i].TB_Nachtrag,
                Datum: budgetTable[i].TB_Datum,
                Skonto: budgetTable[i].TB_Skonto,
                Notiz: budgetTable[i].TB_Notiz,
                Auftr_x00e4_geId: assignmentId,
            };
            await this._sharepointService.addItem({
                listTitle: 'Aufträge-Budgets',
                folder: `${assignmentId}`,
                data: payload,
            });
            await (() =>
                new Promise((resolve) =>
                    setTimeout(() => resolve(null), 500)
                ))();
        }
    }

    public async deleteAssignmentBudgets(
        currentBudgets: { Id: number }[]
    ): Promise<void> {
        const itemsToDelete = this.getBudgetRowsToDelete(currentBudgets);
        for (let i = 0; i < itemsToDelete.length; i++) {
            await this._sharepointService.recycleItem({
                listTitle: 'Aufträge-Budgets',
                id: itemsToDelete[i],
            });
            await (() =>
                new Promise((resolve) =>
                    setTimeout(() => resolve(null), 500)
                ))();
        }
    }

    public getBudgetRowsToDelete(currentBudgets: { Id: number }[]): number[] {
        const initialBudgetsIds = this.assignmentInitialBudgets.map(
            (item) => item.Id
        );
        const currentBudgetsIds = currentBudgets
            .filter((item) => !!item.Id)
            .map((item) => item.Id);
        return initialBudgetsIds.filter(
            (value) => !currentBudgetsIds.includes(value)
        );
    }

    public loadAssignmentBudgetsByCamlQuery(
        camlQuery: string
    ): Observable<AssignmentBudget[]> {
        return from(this.getFilteredItemsPaged('Aufträge-Budgets', camlQuery));
    }

    async getFilteredItemsPaged(
        listTitle: string,
        camlQuery: string
    ): Promise<any[]> {
        // Convert to caml query object

        let allItems = [];
        let items = await this._sharepointService.web.lists
            .getByTitle(listTitle)
            .getItemsByCAMLQuery(this.getCamlQueryObject(camlQuery));

        allItems = allItems.concat(items);

        while (items.hasNext) {
            await new Promise((res) => setTimeout(res, 500)); // kleine Pause, um Überlastung zu vermeiden
            items = await items.getNext();
            allItems = allItems.concat(items);
        }

        return allItems;
    }

    getCamlQueryObject(camlQuery: string): ICamlQuery {
        // Create CamlQuery object with RowLimit of 1000 and recursiveAll to include all folders
        const camlQueryObject: ICamlQuery = {
            ViewXml: `<View Scope='RecursiveAll'><Query>${camlQuery}</Query><RowLimit>1000</RowLimit></View>`,
        };

        return camlQueryObject;
    }

    public reset(): void {
        this.assignmentInitialNebenkostenEuro = 0;
        this.assignmentInitialSkontoTotal = 0;
        this.assignmentInitialBudgets = [];
    }
}
