import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailInvolvedComponent } from './detail-involved.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { TableTaskModule } from '../../task/table-task/table-task.module';
import { MbscModule } from '@mobiscroll/angular';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [DetailInvolvedComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        TableTaskModule,
        MbscModule,
        DragDropModule,
    ],
    exports: [DetailInvolvedComponent],
})
export class DetailInvolvedModule {}
