import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1042Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const processstepList =
                this.sharePointService.web.lists.getByTitle('Prozessschritte');

            await this._updateService.addField(
                processstepList,
                'Aufgabenstatus',
                FieldType.Choice,
                null,
                [
                    'Nicht begonnen',
                    'In Bearbeitung',
                    'Abgeschlossen',
                    'Zurückgestellt',
                    'Wartet auf jemand anderen',
                ]
            );

            // Async iteration through the items in the list
            const items = [];
            for await (const batch of await processstepList.items.top(5000)()) {
                items.push(...batch);
            }

            const updatePromises = items.map((processStep) => {
                let status = 'Nicht begonnen';
                if (processStep.Title === 'Bearbeitung') {
                    status = 'In Bearbeitung';
                } else if (processStep.Title === 'Abgeschlossen') {
                    status = 'Abgeschlossen';
                }

                return processstepList.items.getById(processStep.Id).update({
                    Aufgabenstatus: status,
                });
            });

            await Promise.all(updatePromises);
            console.log('All items updated successfully.');
        } catch (error) {
            console.error('Error during the run operation:', error);
            throw error;
        }
    }
}
