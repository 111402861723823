import { Component, OnInit, ViewEncapsulation, ViewChildren } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import {
    TableConfiguration,
    ColumnTyp,
    FormatTypes,
    CommandType,
    SharePointService,
    TableColumn,
} from 'sp-office365-framework';
import { ActivatedRoute } from '@angular/router';
import * as CamlBuilder from 'camljs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../services/application.service';

@Component({
    selector: 'app-costRoles-assignment',
    templateUrl: './costRoles-assignment.component.html',
    styleUrls: ['./costRoles-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CostRolesAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public header;
    public sharePointItem;
    public formioConfiguration;

    public tableConfig: TableConfiguration;
    public tableConfigNew: TableConfiguration;
    public subTableConfig: TableConfiguration;
    public columns: TableColumn[];
    public subTableColumns: TableColumn[];

    @ViewChildren('costRoleGrid') costRoleGrid;
    @ViewChildren('costRoleEdits') costRoleEdits;

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.header = this._translateService.instant('TASKS');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id != null) {
                this.sharePointService
                    .getItemById({ listTitle: 'Aufträge', id: this.id })
                    .then((assignment) => {
                        this.sharePointItem = assignment;
                        this.itemTitle = this.sharePointItem.Title;

                        if (this.sharePointItem.Projektnummer) {
                            this.itemTitle =
                                this.sharePointItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.sharePointItem.Projektnummer +
                                ')';
                        }

                        this.initGrid();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {}

    addItem() {
        var x = this.costRoleEdits._results[0].show();
    }

    onClose() {
        this.costRoleGrid._results[0].refresh();
    }

    get camlQueryCostRoles() {
        return new CamlBuilder()
            .Where()
            .LookupField('Projekt')
            .Id()
            .EqualTo(this.sharePointItem.Id)
            .ToString();
    }

    get camlQueryInvoices() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .ToString();
    }

    get camlQueryMyWorkPackages() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this.sharePointService.currentUser.Id)
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryCriticalWorkPackages() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .OrderBy('Title')
            .ToString();
    }

    get camlQueryTasks() {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        return (
            '<Where><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            '</Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    get camlQueryMyTasks(): string {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let currentUserExpression = CamlBuilder.Expression()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this.sharePointService.currentUser.Id)
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            currentUserExpression +
            '</And></Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    get camlQueryCriticalTasks(): string {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let markExpression = CamlBuilder.Expression()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            markExpression +
            '</And></Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    initGrid() {
        if (!this.sharePointItem) {
            return;
        }
/*
        this.subTableColumns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('COLUMNS.TASK'),
                hidden: false,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant('COLUMNS.DUEDATE'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant('COLUMNS.ASSIGNED'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Status',
                title: this._translateService.instant('COLUMNS.STATUS'),
                hidden: false,
            },
            {
                internalName: 'Arbeitspaket',
                title: this._translateService.instant('COLUMNS.TITLE'),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.subTableConfig = {
            list: 'Auftragsaufgaben',
            columns: this.subTableColumns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            folderName: this.id + '',
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant('BUTTONS.NEWTASK'),
                    command: 'AddTask',
                    color: 'accent',
                },
                {
                    title: this._translateService.instant('BUTTONS.MYTASK'),
                    command: CommandType.changeQuery,
                    query: this.camlQueryMyTasks,
                    color: 'basic',
                },
                {
                    title: this._translateService.instant(
                        'BUTTONS.CRITICALTASK'
                    ),
                    command: CommandType.changeQuery,
                    query: this.camlQueryCriticalTasks,
                    color: 'basic',
                },
            ],
            isDocumentLibrary: false,
            camlQuery: this.camlQueryTasks,
            parentItemLookupName: 'Arbeitspaket',
        };*/

        this.columns = [
            /*{
                internalName: 'Title',
                title: this._translateService.instant('COLUMNS.TITLE'),
                hidden: true,
            },*/
            {
                internalName: 'Kostenrolle',
                title: this._translateService.instant('COLUMNS.TITLE'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            /*{
                internalName: 'Abbreviation',
                title: this._translateService.instant(
                    'COLUMNS.ABBREVIATION'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },*/
            {
                internalName: 'Stundensatz',
                title: this._translateService.instant(
                    'COLUMNS.COSTSPERHOUR'
                ),
                hidden: false,
                //type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfigNew = {
            list: 'Projekt_Kostenrollen',
            columns: this.columns,
            showEditButton: true,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            useMaterialDesign: true,
            toolbar: [],
            isDocumentLibrary: false,
            camlQuery: this.camlQueryCostRoles,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        //customDefaultValue: "value = data.Kostenrolle + '_' + '" + this.sharePointItem.Id + "';",
                        calculateValue: "value = 'Kostenrolle' + data.KostenrolleId + '_Projekt" + this.sharePointItem.Id + "';",
                        clearOnHide: false,
                        hidden: true,
                        /*validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },*/
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'COLUMNS.COSTROLE'
                        ),
                        type: 'sharePointAutocomplete',
                        key: 'KostenrolleId',
                        selectThreshold: 0.3,
                        list: 'Kostenrollen',
                        searchQueryIfIsEmpty: new CamlBuilder()
                            .Where()
                            .NumberField('ID')
                            .NotEqualTo(-1)
                            .OrderBy('Title')
                            .ToString(),
                        isPeoplePicker: false,
                        removable: true,
                        // isCreateMode: this.isCreateMode,
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                    },
                    {
                        label: this._translateService.instant(
                            'COLUMNS.PROJECT'
                        ),
                        type: 'textfield',
                        key: 'ProjektId',
                        //customDefaultValue: "value = data.Kostenrolle + '_' + '" + this.sharePointItem.Id + "';",
                        calculateValue: "value=" + this.sharePointItem.Id + ";",
                        clearOnHide: false,
                        hidden: true,
                        /*validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },*/
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'COLUMNS.COSTSPERHOUR'
                        ),
                        description: this._translateService.instant(
                            'COLUMNS.INEURO'
                        ),
                        type: 'number',
                        key: 'Stundensatz',
                        decimalLimit: 2,
                        requireDecimal: true,
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'BUTTONS.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
            //configurationSubtable: this.subTableConfig,
        };

        this.tableConfig = {
            list: 'Projekt_Kostenrollen',
            columns: this.columns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            //folderName: this.id + '',
            useMaterialDesign: true,
            toolbar: [
                /*{
                    title: this._translateService.instant('BUTTONS.NEW'),
                    command: 'Add',
                    color: 'accent',
                },*/
            ],
            isDocumentLibrary: false,
            camlQuery: this.camlQueryCostRoles,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    /*{
                        label: this._translateService.instant(
                            'COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                        disabled: true,
                    },*/
                    {
                        label: this._translateService.instant(
                            'COLUMNS.COSTROLE'
                        ),
                        type: 'sharePointAutocomplete',
                        key: 'KostenrolleId',
                        selectThreshold: 0.3,
                        list: 'Kostenrollen',
                        searchQueryIfIsEmpty: new CamlBuilder()
                            .Where()
                            .NumberField('ID')
                            .NotEqualTo(-1)
                            .OrderBy('Title')
                            .ToString(),
                        isPeoplePicker: false,
                        removable: true,
                        // isCreateMode: this.isCreateMode,
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        disabled: true,
                    },
                    /*{
                        label: this._translateService.instant(
                            'COLUMNS.ABBREVIATION'
                        ),
                        type: 'textfield',
                        key: 'Abbreviation',
                        inputFormat: 'plain',
                        disabled: true,
                    },*/
                    {
                        label: this._translateService.instant(
                            'COLUMNS.COSTSPERHOUR'
                        ),
                        description: this._translateService.instant(
                            'COLUMNS.INEURO'
                        ),
                        type: 'number',
                        key: 'Stundensatz',
                        decimalLimit: 2,
                        requireDecimal: true,
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'BUTTONS.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
            //configurationSubtable: this.subTableConfig,
        };
    }
}
