import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { ColumnTyp, SharePointService } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { Assignment, Bill, Standort, Team } from 'src/app/main/shared/models';
import {
    MAP_PROJECT_STATUS,
    PROJECT_EVALUATION_DEFAULT_BILL,
    PROJECT_EVALUATION_DEFAULT_BUDGET,
    QUARTER_DATES_RANGE,
} from './project-evaluation.constant';
import { TimeService } from '../services/time.service';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { splitArrayIntoChunks, toFixed } from '../shared/shared.util';
import { TranslateService } from '@ngx-translate/core';
import { AssignmentBudgetService } from '../services/assignment-budget.service';
import { AssignmentStatus } from '../shared/enums';
import { DateUtil } from '../shared/utils';
import moment from 'moment';
import { ApplicationService } from '../services/application.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectEvaluationService {
    public currentMonth = -1;
    public currentQuarter = -1;
    public currentYear = new Date().getFullYear();
    public currentTeam: Team;
    public currentLocation: Standort;
    public closedProjectsHidden = true;
    public internalProjectsHidden = true;
    public assignmentBudget: Record<number, any> = {};
    public bills: Record<number, any> = {};
    public projects$ = new BehaviorSubject([]);
    public totalProjectSum$ = new BehaviorSubject(0);
    public totalProjectBudget$ = new BehaviorSubject(0);
    public totalBills$ = new BehaviorSubject(0);
    public roleColumns$ = new BehaviorSubject([]);
    public clerkColumns$ = new BehaviorSubject([]);
    private _loadingRoleColumn$ = new BehaviorSubject(true);
    public loadingRoleColumn$ = this._loadingRoleColumn$.asObservable();
    public _triggerFilter$ = new Subject();
    public triggerFilter$ = this._triggerFilter$.asObservable();
    private resetLoadingProjects$ = new Subject();
    private destroy$ = new Subject();

    constructor(
        public _sharepointService: SharePointService,
        private _timeService: TimeService,
        private _translateService: TranslateService,
        private _assignmentBudgetService: AssignmentBudgetService,
        private _applicationService: ApplicationService
    ) {
        this.triggerFilter$
            .pipe(debounceTime(1500))
            .subscribe(() => this.filterProjects1());
    }

    public filterProjects(): void {
        this._triggerFilter$.next(undefined);
    }

    public filterProjects1() {
        console.warn('FILTER');
        this.resetLoadingProjects$.next(undefined);
        let startDate: Date;
        let endDate: Date;

        if (this.currentMonth !== -1) {
            startDate = new Date(this.currentYear, this.currentMonth - 1, 1);
            endDate = new Date(this.currentYear, this.currentMonth - 1, 5);
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(0);
        } else {
            if (this.currentQuarter && this.currentQuarter !== -1) {
                const quarterMonths = QUARTER_DATES_RANGE[this.currentQuarter];
                startDate = new Date(
                    this.currentYear,
                    quarterMonths.startMonth,
                    1
                );
                endDate = new Date(this.currentYear, quarterMonths.endMonth, 1);
                endDate.setMonth(endDate.getMonth() + 1);
                endDate.setDate(0);
            } else {
                startDate = new Date(this.currentYear, 0, 1);
                endDate = new Date(this.currentYear, 11, 31);
            }
        }

        startDate = DateUtil.getConvertToUTCDate(startDate);
        endDate = moment(endDate).endOf('day').toDate();

        this._loadingRoleColumn$.next(true);
        this.getAssignments(startDate, endDate)
            .pipe(takeUntil(this.resetLoadingProjects$))
            .subscribe((result: Assignment[]) => {
                let projects = result.filter(
                    (project) =>
                        (this.assignmentBudget[project.Id] ||
                            this.bills[project.Id]) &&
                        (!project.FieldValuesAsText.Fachbereich.startsWith(
                            '999'
                        ) ||
                            !this.internalProjectsHidden)
                );

                projects.forEach((project) => {
                    if (project.Auftragsstart) {
                        project.Auftragsstart = new Date(project.Auftragsstart);
                    }

                    if (project.Auftragsende) {
                        project.Auftragsende = new Date(project.Auftragsende);
                    }

                    project._Budgets =
                        this.assignmentBudget[project.Id] ??
                        PROJECT_EVALUATION_DEFAULT_BUDGET;
                    project._Bills =
                        this.bills[project.Id] ??
                        PROJECT_EVALUATION_DEFAULT_BILL;
                    project._RemainingFee =
                        project._Budgets.Antragssumme +
                        project._Budgets.NebenkostenEuro -
                        project._Bills.Total;
                    project.Auftragsstatus = this._translateService.instant(
                        MAP_PROJECT_STATUS[project.Auftragsstatus] ??
                            'NO_STATUS'
                    );
                    this.addClerkColumns(project);
                });

                if (this.currentTeam) {
                    projects = projects.filter(
                        (project) => project.TeamId === this.currentTeam.Id
                    );
                }

                if (this.currentLocation) {
                    projects = projects.filter(
                        (project) =>
                            project.StandortId === this.currentLocation.Id
                    );
                }

                const [totalProjectSum, totalProjectBudget, totalBills] =
                    projects.reduce(
                        (prev, current) => [
                            current._Budgets.Antragssumme + prev[0],
                            current._Budgets.Auftragsbudget + prev[1],
                            current._Bills.Internal + prev[2],
                        ],
                        [0, 0, 0]
                    );
                this.projects$.next(projects);
                this.totalProjectSum$.next(totalProjectSum);
                this.totalProjectBudget$.next(totalProjectBudget);
                this.totalBills$.next(totalBills);

                if (!projects.length) {
                    this._loadingRoleColumn$.next(false);
                    return;
                }

                this.loadKostenrolleColumns(projects, startDate, endDate);
            });
    }

    private addClerkColumns(assignment: any): void {
        const clerks = assignment.FieldValuesAsText.Sachbearbeiter?.split(';');
        const clerkColumns = [];
        clerks.forEach((clerk: string, index: number) => {
            const fieldKey = `clerk${index}`;
            assignment[fieldKey] = clerk;
            clerkColumns.push({
                internalName: fieldKey,
                title: `SB${index + 1}`,
                hidden: false,
                type: ColumnTyp.Text,
            });
        });

        if (clerkColumns.length > this.clerkColumns$.getValue().length) {
            this.clerkColumns$.next(clerkColumns);
        }
    }

    private loadKostenrolleColumns(
        projects: Assignment[],
        startDate: Date,
        endDate: Date
    ) {
        const projectIds = projects.map((project) => project.Id);
        this._timeService
            .loadProjectEvaluationAllTimes(
                projectIds,
                startDate,
                endDate,
                this.currentYear
            )
            .pipe(
                takeUntil(this.resetLoadingProjects$),
                switchMap((times) => {
                    const usersIds = [
                        ...new Set(times.map((item) => item?.AuthorId)),
                    ] as number[];
                    return from(
                        this._sharepointService.getListItems({
                            title: 'Mitarbeiter',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .UserField('User')
                                .Id()
                                .In(usersIds.length ? usersIds : [-1])
                                .ToString(),
                        })
                    ).pipe(map((users) => [times, users]));
                })
            )
            .subscribe(([times, mitarbeiters]) => {
                const userCosts = {};
                const costHours = {};
                const roleColumns = [];
                const columnIds = {};
                const projectHours = {};

                mitarbeiters.forEach(
                    (user) =>
                        (userCosts[user.UserId] =
                            user.FieldValuesAsText.Kostenrolle)
                );
                times.forEach((time) => {
                    if (!costHours[time.ProjektId]) {
                        costHours[time.ProjektId] = {};
                        projectHours[time.ProjektId] = 0;
                    }

                    projectHours[time.ProjektId] =
                        projectHours[time.ProjektId] + time.Kosten;

                    let userKey =
                        userCosts[time.AuthorId] ??
                        `${
                            time.FieldValuesAsText?.Author ??
                            'Unbekannter Autor'
                        }_(NOT_MITARBEITER)`;

                    if (!costHours[time.ProjektId][userKey]) {
                        costHours[time.ProjektId][userKey] = 0;
                    }

                    costHours[time.ProjektId][userKey] = toFixed(
                        costHours[time.ProjektId][userKey] + time.Minuten / 60,
                        2
                    );
                });

                for (let i = 0; i < projects.length; i++) {
                    if (costHours[projects[i].Id]) {
                        projects[i] = {
                            ...projects[i],
                            ...costHours[projects[i].Id],
                        };
                    }

                    const gewinnmarge = projects[i].Gewinnmarge ?? 0;
                    projects[i]._TotalHours = projectHours[projects[i].Id] ?? 0;
                    projects[i]._WorkInProgressCost =
                        projects[i]._Budgets.Auftragsbudget -
                        projects[i]._TotalHours;
                    projects[i]._Arbeitsvorrat =
                        projects[i]._Budgets.Antragssumme +
                        projects[i]._Budgets.NebenkostenEuro -
                        projects[i]._Budgets.Fremdleistung -
                        (gewinnmarge / 100) *
                            (projects[i]._Budgets.Antragssumme +
                                projects[i]._Budgets.NebenkostenEuro) -
                        projects[i]._TotalHours;
                    projects[i]._GewinnEuro =
                        projects[i]._Budgets.Antragssumme +
                        projects[i]._Budgets.NebenkostenEuro -
                        projects[i]._TotalHours -
                        projects[i]._Budgets.Fremdleistung;
                    projects[i]._GewinnPercent = Math.round(
                        (projects[i]._GewinnEuro /
                            (projects[i]._Budgets.Antragssumme +
                                projects[i]._Budgets.NebenkostenEuro)) *
                            100
                    );
                }

                Object.keys(costHours).forEach((projectId) => {
                    Object.keys(costHours[projectId]).forEach((key) => {
                        if (!columnIds[key]) {
                            columnIds[key] = key;
                            roleColumns.push({
                                internalName: key,
                                title: key,
                                hidden: false,
                                type: ColumnTyp.Text,
                            });
                        }
                    });
                });
                this.roleColumns$.next(roleColumns);
                this.projects$.next(projects);
                this._loadingRoleColumn$.next(false);
            });
    }

    private getAssignments(
        startDate: Date,
        endDate: Date
    ): Observable<Assignment[]> {
        return this.loadAssignmentBudgets(startDate, endDate).pipe(
            switchMap((projectIds) => this.loadAllProjects(projectIds))
        );
    }

    private loadAssignmentBudgets(
        startDate: Date,
        endDate: Date
    ): Observable<number[]> {
        const budget$ = this.currentYear
            ? this._assignmentBudgetService.loadAssignmentBudgetsByCamlQuery(
                  new CamlBuilder()
                      .Where()
                      .DateField('Datum')
                      .GreaterThanOrEqualTo(startDate)
                      .And()
                      .DateField('Datum')
                      .LessThanOrEqualTo(endDate)
                      .ToString()
              )
            : from(
                  this._applicationService.getAllItemsPaged('Aufträge-Budgets')
              );

        return budget$.pipe(
            switchMap((assignmentBudgets) => {
                this.assignmentBudget = {};
                assignmentBudgets.forEach((assignmentBudget) => {
                    let assignmentRecord =
                        this.assignmentBudget[
                            assignmentBudget.Auftr_x00e4_geId
                        ];

                    if (!assignmentRecord) {
                        this.assignmentBudget[
                            assignmentBudget.Auftr_x00e4_geId
                        ] = {
                            Antragssumme: 0,
                            Nebenkosten: 0,
                            Fremdleistung: 0,
                            NebenkostenEuro: 0,
                            Auftragsbudget: 0,
                        };
                        assignmentRecord =
                            this.assignmentBudget[
                                assignmentBudget.Auftr_x00e4_geId
                            ];
                    }

                    const nebenkostenEuro =
                        ((assignmentBudget.Antragssumme ?? 0) / 100) *
                        (assignmentBudget.Nebenkosten ?? 0);
                    const skonto =
                        assignmentBudget.Skonto && assignmentBudget.Antragssumme
                            ? (assignmentBudget.Antragssumme +
                                  nebenkostenEuro) *
                              0.03
                            : 0;
                    assignmentRecord.Antragssumme =
                        assignmentRecord.Antragssumme +
                        assignmentBudget.Antragssumme;
                    assignmentRecord.Nebenkosten =
                        assignmentRecord.Nebenkosten +
                        assignmentBudget.Nebenkosten;
                    assignmentRecord.NebenkostenEuro =
                        assignmentRecord.NebenkostenEuro + nebenkostenEuro;
                    assignmentRecord.Fremdleistung =
                        assignmentRecord.Fremdleistung +
                        assignmentBudget.Fremdleistung;
                    assignmentRecord.Auftragsbudget =
                        assignmentRecord.Auftragsbudget +
                        ((assignmentBudget.Antragssumme ?? 0) +
                            (nebenkostenEuro ?? 0) -
                            (assignmentBudget.Fremdleistung ?? 0)) -
                        skonto;
                });

                const budgetProjectIds = Object.keys(this.assignmentBudget).map(
                    (key) => Number(key)
                );

                return from(this.loadBills(startDate, endDate)).pipe(
                    map((billsProjectIds) => [
                        ...new Set([...budgetProjectIds, ...billsProjectIds]),
                    ])
                );
            })
        );
    }

    private async loadBills(startDate: Date, endDate: Date): Promise<any> {
        if (!this.currentYear) {
            // Fallback, wenn kein Jahr gesetzt ist
            return this._applicationService
                .getAllItemsPaged('Rechnungen')
                .then((bills) => this.processBills(bills));
        }

        const bills = await this._applicationService.getFilteredItemsPaged(
            'Rechnungen',
            new CamlBuilder()
                .Where()
                .NumberField('Jahr')
                .EqualTo(this.currentYear)
                .And()
                .NumberField('Monat')
                .GreaterThanOrEqualTo(startDate.getMonth() + 1)
                .And()
                .NumberField('Monat')
                .LessThanOrEqualTo(endDate.getMonth() + 1)
                .ToString()
        );
        return this.processBills(bills);
    }

    private processBills(bills: any[]): any {
        this.bills = {};
        bills.forEach((bill) => {
            let billRecord = this.bills[bill.ProjektId] || {
                Internal: 0,
                External: 0,
                Total: 0,
            };
            billRecord.Internal += bill.Forderung;
            billRecord.Total += bill.Forderung;
            this.bills[bill.ProjektId] = billRecord;
        });
        return Object.keys(this.bills).map((key) => Number(key));
    }

    private loadAllProjects(projectIds: number[]) {
        if (!projectIds.length) {
            return of([]);
        }

        if (!this.currentYear) {
            return from(
                this._sharepointService.getListItems({
                    title: 'Aufträge',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .And()
                        .ChoiceField('Auftragsstatus')
                        .In(this.getAssignmentFilterStatuses())
                        .ToString(),
                    viewFields: [
                        { internalName: 'ID' },
                        { internalName: 'Auftragsstart' },
                        { internalName: 'Projektnummer' },
                        { internalName: 'OW_Nummer' },
                        { internalName: 'Auftragsende' },
                        { internalName: 'Antragssumme' },
                        { internalName: 'Fremdleistung' },
                        { internalName: 'Auftragsbudget' },
                        { internalName: 'Team' },
                        { internalName: 'Standort' },
                        { internalName: 'Title' },
                        { internalName: 'Budget_x0020__x0028_in_x0020_Stu' },
                        { internalName: 'Budget_x0020__x0028_in_x0020_Tag' },
                        { internalName: 'Nebenkosten' },
                        { internalName: 'Auftragsstatus' },
                        { internalName: 'Kunde' },
                        { internalName: 'Kostenstelle' },
                        { internalName: 'Fachbereich' },
                        { internalName: 'Auftragsleiter' },
                        { internalName: 'Sachbearbeiter' },
                        { internalName: 'Gewinnmarge' },
                    ],
                })
            );
        }

        const projectIdsChunks = splitArrayIntoChunks(projectIds);
        const chunks$ = [];

        projectIdsChunks.forEach((projectIdsChunk) => {
            chunks$.push(
                this._sharepointService.getListItems({
                    title: 'Aufträge',
                    isDocumentLibrary: false,
                    camlQuery: this.currentYear
                        ? new CamlBuilder()
                              .Where()
                              .NumberField('ID')
                              .In(projectIdsChunk)
                              .And()
                              .ChoiceField('Auftragsstatus')
                              .In(this.getAssignmentFilterStatuses())
                              .ToString()
                        : new CamlBuilder()
                              .Where()
                              .NumberField('ID')
                              .NotEqualTo(-1)
                              .And()
                              .ChoiceField('Auftragsstatus')
                              .In(this.getAssignmentFilterStatuses())
                              .ToString(),
                    viewFields: [
                        { internalName: 'ID' },
                        { internalName: 'Auftragsstart' },
                        { internalName: 'Projektnummer' },
                        { internalName: 'OW_Nummer' },
                        { internalName: 'Auftragsende' },
                        { internalName: 'Antragssumme' },
                        { internalName: 'Fremdleistung' },
                        { internalName: 'Auftragsbudget' },
                        { internalName: 'Team' },
                        { internalName: 'Standort' },
                        { internalName: 'Title' },
                        { internalName: 'Budget_x0020__x0028_in_x0020_Stu' },
                        { internalName: 'Budget_x0020__x0028_in_x0020_Tag' },
                        { internalName: 'Nebenkosten' },
                        { internalName: 'Auftragsstatus' },
                        { internalName: 'Kunde' },
                        { internalName: 'Kostenstelle' },
                        { internalName: 'Fachbereich' },
                        { internalName: 'Auftragsleiter' },
                        { internalName: 'Sachbearbeiter' },
                        { internalName: 'Gewinnmarge' },
                    ],
                })
            );
        });

        return forkJoin(chunks$).pipe(map((projects) => projects.flat()));
    }

    public resetFilter(): void {
        this.currentLocation = null;
        this.currentTeam = null;
        this.currentMonth = -1;
        this.currentQuarter = -1;
        this.closedProjectsHidden = true;
        this.internalProjectsHidden = true;
        this.currentYear = new Date().getFullYear();
        this.assignmentBudget = {};
        this.bills = {};
        this.clerkColumns$.next([]);
        this.projects$.next([]);
        this._loadingRoleColumn$.next(true);
        this.roleColumns$.next([]);
        this.totalBills$.next(0);
        this.totalProjectBudget$.next(0);
        this.totalProjectSum$.next(0);
        this.destroy$.next(undefined);
    }

    private getAssignmentFilterStatuses(): AssignmentStatus[] {
        return this.closedProjectsHidden
            ? [
                  AssignmentStatus.CREATED,
                  AssignmentStatus.IN_PROGRESS,
                  AssignmentStatus.STOPPED,
              ]
            : [
                  AssignmentStatus.CREATED,
                  AssignmentStatus.IN_PROGRESS,
                  AssignmentStatus.STOPPED,
                  AssignmentStatus.COMPLETED,
              ];
    }
}
