import { FrameworkService } from 'sp-office365-layout';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import '@pnp/sp/items/get-all';

export class UpdateToVersion1149Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _frameworkService: FrameworkService
    ) {}

    async run() {
        try {
            const assignmentsList =
                this._sharePointService.web.lists.getByTitle('Aufträge');
            await this._updateService.runFunctionIfFieldNotExists(
                assignmentsList,
                'Gewinnmarge',
                async () =>
                    await this._updateService.addField(
                        assignmentsList,
                        'Gewinnmarge',
                        FieldType.Number
                    )
            );

            // const batch = this._sharePointService.web.createBatch();
            // const batchItems = assignmentsList.items.inBatch(batch);
            // const assignments = await assignmentsList.items.getAll();
            // assignments.forEach(assignment => {
            //     batchItems.getById(assignment.Id).inBatch(batch).update({ Gewinnmarge: 0 })
            // });
            // await batch.execute();
        } catch (error) {
            throw new Error(error);
        }
    }
}
