import { SchedulerComponent } from './../../scheduler/scheduler.component';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharePointService } from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import * as CamlBuilder from 'camljs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { locale, MbscEventcalendarView } from '@mobiscroll/angular';

@Component({
    selector: 'app-timeline-assignment',
    templateUrl: './timeline-assignment.component.html',
    styleUrls: ['./timeline-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TimelineAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public header;
    public sharePointItem;

    public schedulerReady = false;
    public config: any;

    public events = [];
    public resources = [];
    private startDate: Date;
    private endDate: Date;
    private timerangeDays = Math.round(window.innerWidth / 58.5);
    private workPackages = [];       
    view: MbscEventcalendarView = {
        timeline: {
            type: 'month',
            size: 2
        },
    };
    currentLang = locale['de'];

    get camlQueryWorkPackage() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .OrderBy('Modified')
            .ToString();
    }

    constructor(
        public _applicationService: ApplicationService,
        private readonly route: ActivatedRoute,
        private readonly sharePointService: SharePointService,
        private readonly _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private readonly _translateService: TranslateService,
        private readonly dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.header = this._translateService.instant('TIMELINE.HEADER');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id != null) {
                this.sharePointService
                    .getItemById({ listTitle: 'Aufträge', id: this.id })
                    .then((assignment) => {
                        this.sharePointItem = assignment;
                        this.itemTitle = this.sharePointItem.Title;

                        if (this.sharePointItem.Projektnummer) {
                            this.itemTitle =
                                this.sharePointItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'TIMELINE.PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.sharePointItem.Projektnummer +
                                ')';
                        }

                        // Get all workpackages from project
                        this.sharePointService
                            .getListItems({
                                title: 'Auftragsaufgaben',
                                camlQuery: this.camlQueryWorkPackage,
                                isDocumentLibrary: false,
                                folderName: this.id + '',
                            })
                            .then((elements) => {
                                this.workPackages = elements;

                                this.initScheduler();
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {
        //
    }

    filter(filter) {
        switch (filter) {
            case 0:
                this.view = {
                    timeline: {
                        type: 'week',
                        size: 4
                    },
                };
                break;
            case 6:
                this.view = {
                    timeline: {
                        type: 'month',
                    },
                };
                break;
            case 1:
                this.view = {
                    timeline: {
                        type: 'year'
                    },
                };
                break;
            case 2:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 2
                    },
                };
                break;
            case 3:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 3
                    },
                };
                break;
            case 4:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 4
                    },
                };
                break;
            case 5:
                this.view = {
                    timeline: {
                        type: 'year',
                        size: 5
                    },
                };
                break;
            default:
                this.initScheduler();
                break;
        }
    }

    openDialog(wpId: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.id,
            isTask: false,
            taskId: wpId,
        };

        this.dialog.open(SchedulerComponent, dialogConfig);
    }

    initScheduler() {
        this.startDate = new Date();
        this.endDate = new Date();

        this.resources = [];
        this.events = [];

        this.workPackages.forEach((workpackage) => {
            if (new Date(workpackage.StartDate) < this.startDate) {
                this.startDate = new Date(workpackage.StartDate);
            }

            if (new Date(workpackage.DueDate) > this.endDate) {
                this.endDate = new Date(workpackage.DueDate);
            }

            this.resources.push({
                name: workpackage.Title,
                id: workpackage.Id,
                expanded: false,
                // children: [{ name: 'Production Line A.2', id: 'R2' }]
            });

            this.events.push({
                id: workpackage.Id,
                start: this.addHours(
                    new Date(workpackage.StartDate),
                    2
                ).toISOString(),
                end: this.addHours(
                    new Date(workpackage.DueDate),
                    3
                ).toISOString(),
                resource: workpackage.Id,
                text: workpackage.Title,
                bubbleHtml:
                    '<b>' +
                    workpackage.Title +
                    '</b></br>' +
                    this._translateService.instant('START') +
                    ': ' +
                    new Date(workpackage.StartDate).toLocaleDateString() +
                    '</br>' +
                    this._translateService.instant('END') +
                    ': ' +
                    new Date(workpackage.DueDate).toLocaleDateString(),
            });
        });

        if (
            this.timerangeDays <
            Math.ceil((+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24))
        ) {
            this.timerangeDays = Math.ceil(
                (+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24)
            );
        }

    }

    addHours(date: Date, h: number): Date {
        const result = new Date(date);
        result.setTime(date.getTime() + h * 60 * 60 * 1000);

        return result;
    }

    onCellClick(event: any): void {
        this.openDialog(event.event.id)
    }
}
