import { FuseNavigationItem } from 'sp-office365-layout';

export const newProjectChild: FuseNavigationItem = {
    id: 'new',
    title: 'Neues Projekt',
    translate: 'NAV.NEW.TITLE',
    type: 'basic',
    icon: 'add',
    link: '/new-project',
    queryParamsHandling: 'merge'
};

export const navigation: FuseNavigationItem[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.HEADER',
        type: 'group',
        children: [
            // {
            //     id: 'start',
            //     title: 'Start',
            //     translate: 'NAV.START.TITLE',
            //     type: 'basic',
            //     icon: 'apps',
            //     link: '/dashboard',
            // },
            {
                id: 'scheduler',
                title: 'Zeitplan',
                translate: 'NAV.SCHEDULER.TITLE',
                type: 'basic',
                icon: 'schedule',
                link: '/scheduler',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newOrder',
                title: 'Neuer Auftrag',
                translate: 'NAV.NEWORDER.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-order',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newRequest',
                title: 'Neue Anfrage',
                translate: 'NAV.NEWREQUEST.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-request',
                queryParamsHandling: 'merge'
            },
            {
                id: 'currentuser',
                title: 'Mein Bereich',
                translate: 'NAV.CURRENTUSER.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/myviews',
                queryParamsHandling: 'merge'
            },
            {
                id: 'assignments',
                title: 'Projekte',
                translate: 'NAV.ASSIGNMENTS.TITLE',
                type: 'basic',
                icon: 'assignment',
                link: '/projects',
                queryParamsHandling: 'merge'
            },
            {
                id: 'orders',
                title: 'Aufträge',
                translate: 'NAV.ORDERS.TITLE',
                type: 'basic',
                icon: 'call_missed_outgoing',
                link: '/orders',
                queryParamsHandling: 'merge'
            },
            {
                id: 'Request',
                title: 'Anfragen',
                translate: 'NAV.REQUEST.TITLE',
                type: 'basic',
                icon: 'call_missed',
                link: '/requests',
                queryParamsHandling: 'merge'
            },
            {
                id: 'workpackages',
                title: 'Arbeitspakete',
                translate: 'NAV.WORKPACKAGES.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/workpackages',
                queryParamsHandling: 'merge'
            },
            {
                id: 'tasks',
                title: 'Aufgaben',
                translate: 'NAV.TASKS.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/tasks',
                queryParamsHandling: 'merge'
            },
            {
                id: 'customers',
                title: 'Kunden',
                translate: 'NAV.CUSTOMERS.TITLE',
                type: 'basic',
                icon: 'contacts',
                link: '/customers',
                queryParamsHandling: 'merge'
            },
        ],
    },
];

export const controllingNavigationChild: FuseNavigationItem = {
    id: 'controlling',
    title: 'Controlling',
    translate: 'NAV.CONTROLLING.TITLE',
    type: 'collapsable',
    icon: 'attach_money',
    children: [
        {
            id: 'setPoints',
            title: 'Soll-Zahlen',
            translate: 'NAV.TARGET.TITLE',
            type: 'basic',
            icon: 'assessment',
            link: '/targetfigures',
            queryParamsHandling: 'merge'
        },
        {
            id: 'bills',
            title: 'Rechnungen',
            translate: 'NAV.BILLS.TITLE',
            type: 'basic',
            icon: 'monetization_on',
            link: '/bills',
            queryParamsHandling: 'merge'
        },
        {
            id: 'evaluation',
            title: 'Auswertung',
            translate: 'NAV.EVALUATION.TITLE',
            type: 'basic',
            icon: 'my_library_books',
            link: '/evaluation',
            queryParamsHandling: 'merge'
        },
        {
            id: 'projectEvaluation',
            title: 'Projektliste',
            translate: 'NAV.PROJECT_EVALUATION.TITLE',
            type: 'basic',
            icon: 'my_library_books',
            link: '/project-evaluation',
            queryParamsHandling: 'merge'
        },
        {
            id: 'evaluation',
            title: 'Personenauswertung',
            translate: 'NAV.EMPLOYEEANALYSIS.TITLE',
            type: 'basic',
            icon: 'my_library_books',
            link: '/employeeanalysis',
            queryParamsHandling: 'merge'
        },
        {
            id: 'partiallyFinishedServices',
            title: 'Teilfertigeleistungen',
            translate: 'NAV.PARTIALLY_FINISHED_SERVICES.TITLE',
            type: 'basic',
            icon: 'my_library_books',
            link: '/partially-finished-services',
            queryParamsHandling: 'merge'
        },
    ],
};

export const navigationWithTimes: FuseNavigationItem[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.HEADER',
        type: 'group',
        children: [
            // {
            //     id: 'start',
            //     title: 'Start',
            //     translate: 'NAV.START.TITLE',
            //     type: 'basic',
            //     icon: 'apps',
            //     link: '/dashboard',
            // },
            {
                id: 'scheduler',
                title: 'Zeitplan',
                translate: 'NAV.SCHEDULER.TITLE',
                type: 'basic',
                icon: 'schedule',
                link: '/scheduler',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newOrder',
                title: 'Neuer Auftrag',
                translate: 'NAV.NEWORDER.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-order',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newRequest',
                title: 'Neue Anfrage',
                translate: 'NAV.NEWREQUEST.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-request',
                queryParamsHandling: 'merge'
            },
            {
                id: 'currentuser',
                title: 'Mein Bereich',
                translate: 'NAV.CURRENTUSER.TITLE',
                type: 'basic',
                icon: 'person',
                link: '/myviews',
                queryParamsHandling: 'merge'
            },
            {
                id: 'assignments',
                title: 'Projekte',
                translate: 'NAV.ASSIGNMENTS.TITLE',
                type: 'basic',
                icon: 'assignment',
                link: '/projects',
                queryParamsHandling: 'merge'
            },
            {
                id: 'orders',
                title: 'Aufträge',
                translate: 'NAV.ORDERS.TITLE',
                type: 'basic',
                icon: 'call_missed_outgoing',
                link: '/orders',
                queryParamsHandling: 'merge'
            },
            {
                id: 'Request',
                title: 'Anfragen',
                translate: 'NAV.REQUEST.TITLE',
                type: 'basic',
                icon: 'call_missed',
                link: '/requests',
                queryParamsHandling: 'merge'
            },
            {
                id: 'workpackages',
                title: 'Arbeitspakete',
                translate: 'NAV.WORKPACKAGES.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/workpackages',
                queryParamsHandling: 'merge'
            },
            {
                id: 'tasks',
                title: 'Aufgaben',
                translate: 'NAV.TASKS.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/tasks',
                queryParamsHandling: 'merge'
            },
            {
                id: 'times',
                title: 'Zeiten',
                translate: 'NAV.TIMES.TITLE',
                type: 'collapsable',
                icon: 'timer',
                children: [
                    {
                        id: 'mytimes',
                        title: 'Meine Zeiten',
                        translate: 'NAV.MYTIMES.TITLE',
                        type: 'basic',
                        icon: 'watch',
                        link: '/times',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'reporting',
                        title: 'Reports',
                        translate: 'NAV.REPORTS.TITLE',
                        type: 'basic',
                        icon: 'equalizer',
                        link: '/reports',
                        queryParamsHandling: 'merge'
                    },
                ],
            },
            {
                id: 'customers',
                title: 'Kunden',
                translate: 'NAV.CUSTOMERS.TITLE',
                type: 'basic',
                icon: 'contacts',
                link: '/customers',
                queryParamsHandling: 'merge'
            },
        ],
    },
];

export const adminNavigation: FuseNavigationItem[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.HEADER',
        type: 'group',
        children: [
            // {
            //     id: 'start',
            //     title: 'Start',
            //     translate: 'NAV.START.TITLE',
            //     type: 'basic',
            //     icon: 'apps',
            //     link: '/dashboard',
            // },
            {
                id: 'scheduler',
                title: 'Zeitplan',
                translate: 'NAV.SCHEDULER.TITLE',
                type: 'basic',
                icon: 'schedule',
                link: '/scheduler',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newOrder',
                title: 'Neuer Auftrag',
                translate: 'NAV.NEWORDER.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-order',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newRequest',
                title: 'Neue Anfrage',
                translate: 'NAV.NEWREQUEST.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-request',
                queryParamsHandling: 'merge'
            },
            {
                id: 'currentuser',
                title: 'Mein Bereich',
                translate: 'NAV.CURRENTUSER.TITLE',
                type: 'basic',
                icon: 'person',
                link: '/myviews',
                queryParamsHandling: 'merge'
            },
            {
                id: 'assignments',
                title: 'Projekte',
                translate: 'NAV.ASSIGNMENTS.TITLE',
                type: 'basic',
                icon: 'assignment',
                link: '/projects',
                queryParamsHandling: 'merge'
            },
            {
                id: 'orders',
                title: 'Aufträge',
                translate: 'NAV.ORDERS.TITLE',
                type: 'basic',
                icon: 'call_missed_outgoing',
                link: '/orders',
                queryParamsHandling: 'merge'
            },
            {
                id: 'Request',
                title: 'Anfragen',
                translate: 'NAV.REQUEST.TITLE',
                type: 'basic',
                icon: 'call_missed',
                link: '/requests',
                queryParamsHandling: 'merge'
            },
            {
                id: 'workpackages',
                title: 'Arbeitspakete',
                translate: 'NAV.WORKPACKAGES.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/workpackages',
                queryParamsHandling: 'merge'
            },
            {
                id: 'tasks',
                title: 'Aufgaben',
                translate: 'NAV.TASKS.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/tasks',
                queryParamsHandling: 'merge'
            },
            {
                id: 'customers',
                title: 'Kunden',
                translate: 'NAV.CUSTOMERS.TITLE',
                type: 'basic',
                icon: 'contacts',
                link: '/customers',
                queryParamsHandling: 'merge'
            },
            {
                id: 'admin',
                title: 'Administration',
                translate: 'NAV.ADMIN.TITLE',
                type: 'collapsable',
                icon: 'settings',
                badge: {
                    title: '',
                    translate: '',
                    bg: '#FDE047',
                    fg: '#000000',
                },
                children: [
                    {
                        id: 'update',
                        title: 'Update',
                        translate: 'NAV.UPDATE.TITLE',
                        type: 'basic',
                        icon: 'update',
                        link: '/administration/update',
                        queryParamsHandling: 'merge'
                    },
                    // {
                    //     id: 'new-tenant-update',
                    //     title: 'Update',
                    //     translate: 'NAV.UPDATE.TITLE',
                    //     type: 'item',
                    //     icon: 'update',
                    //     url: '/administration/new-tenant-update',
                    // },
                    {
                        id: 'configuration',
                        title: 'Konfiguration',
                        translate: 'NAV.CONFIGURATION.TITLE',
                        type: 'basic',
                        icon: 'how_to_reg',
                        link: '/administration/configuration',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'templates',
                        title: 'Vorlagen',
                        translate: 'NAV.TEMPLATES.TITLE',
                        type: 'basic',
                        icon: 'file_copy',
                        link: '/administration/templates',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'groups',
                        title: 'Gruppen',
                        translate: 'NAV.GROUPS.TITLE',
                        type: 'basic',
                        icon: 'groups',
                        link: '/administration/groups',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'sitecontent',
                        title: 'Websiteinhalte',
                        translate: 'NAV.SITECONTENT.TITLE',
                        type: 'basic',
                        icon: 'list',
                        function: () => {
                            const queryString = window.location.search;
                            const urlParams = new URLSearchParams(queryString);
                            let spHostUrl = urlParams.get('SPHostUrl');

                            if (!spHostUrl && window['spHostUrl']) {
                                spHostUrl = window['spHostUrl'];
                            }

                            if (spHostUrl) {
                                window.open(
                                    spHostUrl + '/_layouts/15/viewlsts.aspx',
                                    '_blank'
                                );
                            }
                        },
                    },
                ],
            },
        ],
    },
];

export const adminNavigationWithTimes: FuseNavigationItem[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.HEADER',
        type: 'group',
        children: [
            // {
            //     id: 'start',
            //     title: 'Start',
            //     translate: 'NAV.START.TITLE',
            //     type: 'basic',
            //     icon: 'apps',
            //     link: '/dashboard',
            // },
            {
                id: 'scheduler',
                title: 'Zeitplan',
                translate: 'NAV.SCHEDULER.TITLE',
                type: 'basic',
                icon: 'schedule',
                link: '/scheduler',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newOrder',
                title: 'Neuer Auftrag',
                translate: 'NAV.NEWORDER.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-order',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newRequest',
                title: 'Neue Anfrage',
                translate: 'NAV.NEWREQUEST.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-request',
                queryParamsHandling: 'merge'
            },
            {
                id: 'currentuser',
                title: 'Mein Bereich',
                translate: 'NAV.CURRENTUSER.TITLE',
                type: 'basic',
                icon: 'person',
                link: '/myviews',
                queryParamsHandling: 'merge'
            },
            {
                id: 'assignments',
                title: 'Projekte',
                translate: 'NAV.ASSIGNMENTS.TITLE',
                type: 'basic',
                icon: 'assignment',
                link: '/projects',
                queryParamsHandling: 'merge'
            },
            {
                id: 'orders',
                title: 'Aufträge',
                translate: 'NAV.ORDERS.TITLE',
                type: 'basic',
                icon: 'call_missed_outgoing',
                link: '/orders',
                queryParamsHandling: 'merge'
            },
            {
                id: 'Request',
                title: 'Anfragen',
                translate: 'NAV.REQUEST.TITLE',
                type: 'basic',
                icon: 'call_missed',
                link: '/requests',
                queryParamsHandling: 'merge'
            },
            {
                id: 'workpackages',
                title: 'Arbeitspakete',
                translate: 'NAV.WORKPACKAGES.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/workpackages',
                queryParamsHandling: 'merge'
            },
            {
                id: 'tasks',
                title: 'Aufgaben',
                translate: 'NAV.TASKS.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/tasks',
                queryParamsHandling: 'merge'
            },
            {
                id: 'times',
                title: 'Zeiten',
                translate: 'NAV.TIMES.TITLE',
                type: 'collapsable',
                icon: 'timer',
                children: [
                    {
                        id: 'mytimes',
                        title: 'Meine Zeiten',
                        translate: 'NAV.MYTIMES.TITLE',
                        type: 'basic',
                        icon: 'watch',
                        link: '/times',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'reporting',
                        title: 'Reports',
                        translate: 'NAV.REPORTS.TITLE',
                        type: 'basic',
                        icon: 'description',
                        link: '/reports',
                        queryParamsHandling: 'merge'
                    },
                ],
            },
            {
                id: 'customers',
                title: 'Kunden',
                translate: 'NAV.CUSTOMERS.TITLE',
                type: 'basic',
                icon: 'contacts',
                link: '/customers',
                queryParamsHandling: 'merge'
            },
            {
                id: 'admin',
                title: 'Administration',
                translate: 'NAV.ADMIN.TITLE',
                type: 'collapsable',
                icon: 'settings',
                badge: {
                    title: '',
                    translate: '',
                    bg: '#FDE047',
                    fg: '#000000',
                },
                children: [
                    {
                        id: 'update',
                        title: 'Update',
                        translate: 'NAV.UPDATE.TITLE',
                        type: 'basic',
                        icon: 'update',
                        link: '/administration/update',
                        queryParamsHandling: 'merge'
                    },
                    // {
                    //     id: 'new-tenant-update',
                    //     title: 'Update',
                    //     translate: 'NAV.UPDATE.TITLE',
                    //     type: 'item',
                    //     icon: 'update',
                    //     url: '/administration/new-tenant-update',
                    // },
                    {
                        id: 'configuration',
                        title: 'Konfiguration',
                        translate: 'NAV.CONFIGURATION.TITLE',
                        type: 'basic',
                        icon: 'how_to_reg',
                        link: '/administration/configuration',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'templates',
                        title: 'Vorlagen',
                        translate: 'NAV.TEMPLATES.TITLE',
                        type: 'basic',
                        icon: 'file_copy',
                        link: '/administration/templates',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'groups',
                        title: 'Gruppen',
                        translate: 'NAV.GROUPS.TITLE',
                        type: 'basic',
                        icon: 'groups',
                        link: '/administration/groups',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'sitecontent',
                        title: 'Websiteinhalte',
                        translate: 'NAV.SITECONTENT.TITLE',
                        type: 'basic',
                        icon: 'list',
                        function: () => {
                            const queryString = window.location.search;
                            const urlParams = new URLSearchParams(queryString);
                            let spHostUrl = urlParams.get('SPHostUrl');

                            if (!spHostUrl && window['spHostUrl']) {
                                spHostUrl = window['spHostUrl'];
                            }

                            if (spHostUrl) {
                                window.open(
                                    spHostUrl + '/_layouts/15/viewlsts.aspx',
                                    '_blank'
                                );
                            }
                        },
                    },
                ],
            },
        ],
    },
];

export const adminUpdateNavigation: FuseNavigationItem[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.HEADER',
        type: 'group',
        children: [
            // {
            //     id: 'start',
            //     title: 'Start',
            //     translate: 'NAV.START.TITLE',
            //     type: 'basic',
            //     icon: 'apps',
            //     link: '/dashboard',
            // },
            {
                id: 'scheduler',
                title: 'Zeitplan',
                translate: 'NAV.SCHEDULER.TITLE',
                type: 'basic',
                icon: 'schedule',
                link: '/scheduler',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newOrder',
                title: 'Neuer Auftrag',
                translate: 'NAV.NEWORDER.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-order',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newRequest',
                title: 'Neue Anfrage',
                translate: 'NAV.NEWREQUEST.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-request',
                queryParamsHandling: 'merge'
            },
            {
                id: 'currentuser',
                title: 'Mein Bereich',
                translate: 'NAV.CURRENTUSER.TITLE',
                type: 'basic',
                icon: 'person',
                link: '/myviews',
                queryParamsHandling: 'merge'
            },
            {
                id: 'assignments',
                title: 'Projekte',
                translate: 'NAV.ASSIGNMENTS.TITLE',
                type: 'basic',
                icon: 'assignment',
                link: '/projects',
                queryParamsHandling: 'merge'
            },
            {
                id: 'orders',
                title: 'Aufträge',
                translate: 'NAV.ORDERS.TITLE',
                type: 'basic',
                icon: 'call_missed_outgoing',
                link: '/orders',
                queryParamsHandling: 'merge'
            },
            {
                id: 'Request',
                title: 'Anfragen',
                translate: 'NAV.REQUEST.TITLE',
                type: 'basic',
                icon: 'call_missed',
                link: '/requests',
                queryParamsHandling: 'merge'
            },
            {
                id: 'workpackages',
                title: 'Arbeitspakete',
                translate: 'NAV.WORKPACKAGES.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/workpackages',
                queryParamsHandling: 'merge'
            },
            {
                id: 'tasks',
                title: 'Aufgaben',
                translate: 'NAV.TASKS.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/tasks',
                queryParamsHandling: 'merge'
            },
            {
                id: 'customers',
                title: 'Kunden',
                translate: 'NAV.CUSTOMERS.TITLE',
                type: 'basic',
                icon: 'contacts',
                link: '/customers',
                queryParamsHandling: 'merge'
            },
            {
                id: 'admin',
                title: 'Administration',
                translate: 'NAV.ADMIN.TITLE',
                type: 'collapsable',
                icon: 'settings',
                badge: {
                    title: '1',
                    translate: 'NAV.UPDATE.BADGE',
                    bg: '#F44336',
                    fg: '#FFFFFF',
                },
                children: [
                    {
                        id: 'update',
                        title: 'Update',
                        translate: 'NAV.UPDATE.TITLE',
                        type: 'basic',
                        icon: 'update',
                        link: '/administration/update',
                        queryParamsHandling: 'merge',
                        badge: {
                            title: '1',
                            translate: 'NAV.UPDATE.BADGE',
                            bg: '#F44336',
                            fg: '#FFFFFF',
                        },
                    },
                    // {
                    //     id: 'new-tenant-update',
                    //     title: 'Update',
                    //     translate: 'NAV.UPDATE.TITLE',
                    //     type: 'item',
                    //     icon: 'update',
                    //     url: '/administration/new-tenant-update',
                    // },
                    {
                        id: 'configuration',
                        title: 'Konfiguration',
                        translate: 'NAV.CONFIGURATION.TITLE',
                        type: 'basic',
                        icon: 'how_to_reg',
                        link: '/administration/configuration',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'templates',
                        title: 'Vorlagen',
                        translate: 'NAV.TEMPLATES.TITLE',
                        type: 'basic',
                        icon: 'file_copy',
                        link: '/administration/templates',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'groups',
                        title: 'Gruppen',
                        translate: 'NAV.GROUPS.TITLE',
                        type: 'basic',
                        icon: 'groups',
                        link: '/administration/groups',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'sitecontent',
                        title: 'Websiteinhalte',
                        translate: 'NAV.SITECONTENT.TITLE',
                        type: 'basic',
                        icon: 'list',
                        function: () => {
                            const queryString = window.location.search;
                            const urlParams = new URLSearchParams(queryString);
                            let spHostUrl = urlParams.get('SPHostUrl');

                            if (!spHostUrl && window['spHostUrl']) {
                                spHostUrl = window['spHostUrl'];
                            }

                            if (spHostUrl) {
                                window.open(
                                    spHostUrl + '/_layouts/15/viewlsts.aspx',
                                    '_blank'
                                );
                            }
                        },
                    },
                ],
            },
        ],
    },
];

export const adminUpdateNavigationWithTimes: FuseNavigationItem[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.HEADER',
        type: 'group',
        children: [
            // {
            //     id: 'start',
            //     title: 'Start',
            //     translate: 'NAV.START.TITLE',
            //     type: 'basic',
            //     icon: 'apps',
            //     link: '/dashboard',
            // },
            {
                id: 'scheduler',
                title: 'Zeitplan',
                translate: 'NAV.SCHEDULER.TITLE',
                type: 'basic',
                icon: 'schedule',
                link: '/scheduler',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newOrder',
                title: 'Neuer Auftrag',
                translate: 'NAV.NEWORDER.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-order',
                queryParamsHandling: 'merge'
            },
            {
                id: 'newRequest',
                title: 'Neue Anfrage',
                translate: 'NAV.NEWREQUEST.TITLE',
                type: 'basic',
                icon: 'add',
                link: '/new-request',
                queryParamsHandling: 'merge'
            },
            {
                id: 'currentuser',
                title: 'Mein Bereich',
                translate: 'NAV.CURRENTUSER.TITLE',
                type: 'basic',
                icon: 'person',
                link: '/myviews',
                queryParamsHandling: 'merge'
            },
            {
                id: 'assignments',
                title: 'Projekte',
                translate: 'NAV.ASSIGNMENTS.TITLE',
                type: 'basic',
                icon: 'assignment',
                link: '/projects',
                queryParamsHandling: 'merge'
            },
            {
                id: 'orders',
                title: 'Aufträge',
                translate: 'NAV.ORDERS.TITLE',
                type: 'basic',
                icon: 'call_missed_outgoing',
                link: '/orders',
                queryParamsHandling: 'merge'
            },
            {
                id: 'Request',
                title: 'Anfragen',
                translate: 'NAV.REQUEST.TITLE',
                type: 'basic',
                icon: 'call_missed',
                link: '/requests',
                queryParamsHandling: 'merge'
            },
            {
                id: 'workpackages',
                title: 'Arbeitspakete',
                translate: 'NAV.WORKPACKAGES.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/workpackages',
                queryParamsHandling: 'merge'
            },
            {
                id: 'tasks',
                title: 'Aufgaben',
                translate: 'NAV.TASKS.TITLE',
                type: 'basic',
                icon: 'assignment_turned_in',
                link: '/tasks',
                queryParamsHandling: 'merge'
            },
            {
                id: 'times',
                title: 'Zeiten',
                translate: 'NAV.TIMES.TITLE',
                type: 'collapsable',
                icon: 'timer',
                children: [
                    {
                        id: 'mytimes',
                        title: 'Meine Zeiten',
                        translate: 'NAV.MYTIMES.TITLE',
                        type: 'basic',
                        icon: 'watch',
                        link: '/times',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'reporting',
                        title: 'Reports',
                        translate: 'NAV.REPORTS.TITLE',
                        type: 'basic',
                        icon: 'equalizer',
                        link: '/reports',
                        queryParamsHandling: 'merge'
                    },
                ],
            },
            {
                id: 'customers',
                title: 'Kunden',
                translate: 'NAV.CUSTOMERS.TITLE',
                type: 'basic',
                icon: 'contacts',
                link: '/customers',
                queryParamsHandling: 'merge'
            },
            {
                id: 'admin',
                title: 'Administration',
                translate: 'NAV.ADMIN.TITLE',
                type: 'collapsable',
                icon: 'settings',
                badge: {
                    title: '1',
                    translate: 'NAV.UPDATE.BADGE',
                    bg: '#F44336',
                    fg: '#FFFFFF',
                },
                children: [
                    {
                        id: 'update',
                        title: 'Update',
                        translate: 'NAV.UPDATE.TITLE',
                        type: 'basic',
                        icon: 'update',
                        link: '/administration/update',
                        queryParamsHandling: 'merge',
                        badge: {
                            title: '1',
                            translate: 'NAV.UPDATE.BADGE',
                            bg: '#F44336',
                            fg: '#FFFFFF',
                        },
                    },
                    // {
                    //     id: 'new-tenant-update',
                    //     title: 'Update',
                    //     translate: 'NAV.UPDATE.TITLE',
                    //     type: 'item',
                    //     icon: 'update',
                    //     url: '/administration/new-tenant-update',
                    // },
                    {
                        id: 'configuration',
                        title: 'Konfiguration',
                        translate: 'NAV.CONFIGURATION.TITLE',
                        type: 'basic',
                        icon: 'how_to_reg',
                        link: '/administration/configuration',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'templates',
                        title: 'Vorlagen',
                        translate: 'NAV.TEMPLATES.TITLE',
                        type: 'basic',
                        icon: 'file_copy',
                        link: '/administration/templates',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'groups',
                        title: 'Gruppen',
                        translate: 'NAV.GROUPS.TITLE',
                        type: 'basic',
                        icon: 'groups',
                        link: '/administration/groups',
                        queryParamsHandling: 'merge'
                    },
                    {
                        id: 'sitecontent',
                        title: 'Websiteinhalte',
                        translate: 'NAV.SITECONTENT.TITLE',
                        type: 'basic',
                        icon: 'list',
                        function: () => {
                            const queryString = window.location.search;
                            const urlParams = new URLSearchParams(queryString);
                            let spHostUrl = urlParams.get('SPHostUrl');

                            if (!spHostUrl && window['spHostUrl']) {
                                spHostUrl = window['spHostUrl'];
                            }

                            if (spHostUrl) {
                                window.open(
                                    spHostUrl + '/_layouts/15/viewlsts.aspx',
                                    '_blank'
                                );
                            }
                        },
                    },
                ],
            },
        ],
    },
];
