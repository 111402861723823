import { Timelevel } from './../models/timelevel.model';
import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { LoadingService } from './loading.service';
import { SnackbarService } from './snackbar.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import {
    ColumnTyp,
    FormatTypes,
    SharePointService,
    SharePointTableComponent,
    TableConfiguration,
} from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { filter, map, take, timeout } from 'rxjs/operators';
import { ApplicationService } from 'src/app/main/services/application.service';
import { Bill, Holiday, Team, TimePreserve } from 'src/app/main/shared/models';
import { HolidaysService } from 'src/app/main/services/holidays.service';
import { DateUtil } from 'src/app/main/shared/utils';
import { TeamsService } from 'src/app/main/services/teams.service';
import { ConfirmDialogComponent } from 'src/app/main/administration/configuration/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { createBatch } from '@pnp/sp/batching';

@Injectable({
    providedIn: 'root',
})
export class TimeService {
    public duration = 0;
    public hours = 0;
    public minutes = 0;
    public costsPerHour = 0;
    public date = new Date();
    public description = '';
    public taskId = -1;
    public projectId = -1;
    public sumOfMinutesForSelectedDate = 0;
    public currentUserProfile;
    public currentUserCostRole;
    public tableConfig: TableConfiguration;
    public level: Timelevel = Timelevel.Application;
    public timeGrid: SharePointTableComponent;
    public editMode: boolean = false;
    public itemId: number = -1;
    public timeUpdated = false;
    public newProjectId: number = null;
    public projectNumber: string = null;
    public dailyMitarbeiterHours: Record<number, number> = {};
    public monthlyMitarbeiterHolidayHours: Record<number, any> = {};
    private _maximumUserHoursPerDayLoading$ = new BehaviorSubject(false);
    public maximumUserHoursPerDayLoading$ =
        this._maximumUserHoursPerDayLoading$.asObservable();
    public _maximumUserHoursPerDay$ = new BehaviorSubject(0);
    public maximumUserHoursPerDay$ =
        this._maximumUserHoursPerDay$.asObservable();
    private _userProfileLoaded$ = new BehaviorSubject(false);
    public timeAdded: EventEmitter<void> = new EventEmitter();
    public reinit = new Subject();
    public preservedValues: TimePreserve = {};
    public teamUsers: Record<number, Record<number, boolean>> = {};
    public teamsRecord: Record<number, Team> = {};

    get camlQueryCurrentUser() {
        switch (this.level) {
            case Timelevel.Application:
            case Timelevel.Project:
                return new CamlBuilder()
                    .Where()
                    .UserField('Author')
                    .EqualToCurrentUser()
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Datum')
                    .ToString();
            case Timelevel.Task:
                return new CamlBuilder()
                    .Where()
                    .LookupField('Aufgabe')
                    .Id()
                    .EqualTo(this.taskId)
                    .And()
                    .UserField('Author')
                    .EqualToCurrentUser()
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Datum')
                    .ToString();
        }
    }

    get camlQueryAll() {
        switch (this.level) {
            case Timelevel.Application:
            case Timelevel.Project:
                return new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Datum')
                    .ToString();
            case Timelevel.Task:
                return new CamlBuilder()
                    .Where()
                    .LookupField('Aufgabe')
                    .Id()
                    .EqualTo(this.taskId)
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Datum')
                    .ToString();
        }
    }

    get searchQueryIfIsEmptyForTasks() {
        if (this.projectId != -1) {
            return new CamlBuilder()
                .Where()
                .LookupField('Auftrag')
                .Id()
                .EqualTo(this.projectId)
                .And()
                .NumberField('FSObjType')
                .EqualTo(0)
                .OrderBy('Title')
                .ToString();
        } else {
            return new CamlBuilder()
                .Where()
                .NumberField('FSObjType')
                .EqualTo(0)
                .OrderBy('Title')
                .ToString();
        }
    }

    get searchQueryForTasks() {
        if (this.projectId != -1) {
            return new CamlBuilder()
                .Where()
                .LookupField('Auftrag')
                .Id()
                .EqualTo(this.projectId)
                .And()
                .TextField('Title')
                .Contains('<%searchText%>')
                .And()
                .NumberField('FSObjType')
                .EqualTo(0)
                .OrderBy('Title')
                .ToString();
        } else {
            return new CamlBuilder()
                .Where()
                .TextField('Title')
                .Contains('<%searchText%>')
                .And()
                .NumberField('FSObjType')
                .EqualTo(0)
                .OrderBy('Title')
                .ToString();
        }
    }

    get camlQueryTimesForSelectedDateAndCurrentUser() {
        if (this.date != null) {
            return new CamlBuilder()
                .Where()
                .DateField('Datum')
                .EqualTo(this.getGlobalDate(this.date))
                .And()
                .UserField('Author')
                .EqualToCurrentUser()
                .OrderBy('Datum')
                .ToString();
        } else {
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .OrderBy('Datum')
                .ToString();
        }
    }

    get camlQueryEmployeeProfileForCurrentUser() {
        if (this._sharepointService.currentUser != null) {
            return new CamlBuilder()
                .Where()
                .UserField('User')
                .Id()
                .EqualTo(this._sharepointService.currentUser.Id)
                .OrderByDesc('Title')
                .ToString();
        } else {
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .OrderByDesc('Datum')
                .ToString();
        }
    }

    constructor(
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private holidaysService: HolidaysService,
        private _teamsService: TeamsService,
        private _dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    getProjectNumber(id): Promise<string> {
        return new Promise<string>(async (resolve) => {
            await this._sharepointService
                .getItemById({
                    listTitle: 'Aufträge',
                    id: id,
                })
                .then((result) => {
                    resolve(result.Projektnummer);
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    }

    deleteTimeItem(item) {
        return new Promise<void>((resolve, reject) => {
            this._loadingService.show(
                this._translateService.instant(
                    'TIME_SERVICE.NOTIFICATIONS.DELETING'
                ),
                this._translateService.instant(
                    'TIME_SERVICE.NOTIFICATIONS.WAIT'
                )
            );

            this._sharepointService
                .recycleItem({
                    listTitle: 'Zeiten',
                    id: item.Id,
                })
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'LV_TIME.NOTIFICATIONS.ERROR'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'error-dialog',
                        }
                    );
                    console.error(err);
                    reject();
                });
        });
    }

    saveTime(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            if (this.taskId == -1) {
                this._snackBarService.add(
                    this._translateService.instant(
                        'TIME_SERVICE.NOTIFICATIONS.ERRORTASK'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );
                reject();
            } else if (this.date == null) {
                this._snackBarService.add(
                    this._translateService.instant(
                        'TIME_SERVICE.NOTIFICATIONS.ERRORDATE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );
                reject();
            } else if (this.duration < 1) {
                this._snackBarService.add(
                    this._translateService.instant(
                        'TIME_SERVICE.NOTIFICATIONS.ERRORDURATION'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );
                reject();
            }
            // else if (this.hours < 0 || this.hours > 23) {
            //     this._snackBarService.add(
            //         this._translateService.instant(
            //             'TIME_SERVICE.NOTIFICATIONS.HOURSERROR'
            //         ),
            //         '',
            //         {
            //             duration: 4000,
            //             panelClass: 'error-dialog',
            //         }
            //     );
            //     reject();
            // }
            else if (
                this.minutes < 0 ||
                this.minutes > 59 ||
                this.minutes % 5 != 0
            ) {
                this._snackBarService.add(
                    this._translateService.instant(
                        'TIME_SERVICE.NOTIFICATIONS.MINUTESERROR'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );
                reject();
            } else {
                let confirm$ = of(true);

                if (!this.costsPerHour) {
                    const dialogRef = this._dialog.open(
                        ConfirmDialogComponent,
                        {
                            data: {
                                confirmMessage: this._translateService.instant(
                                    'TIME_SERVICE.NOTIFICATIONS.ZEROCOSTPERHOUR'
                                ),
                            },
                        }
                    );
                    confirm$ = dialogRef.afterClosed();
                }

                confirm$.subscribe((result) => {
                    if (!result) {
                        return;
                    }

                    this._loadingService.show(
                        this._translateService.instant(
                            'TIME_SERVICE.NOTIFICATIONS.SAVING'
                        ),
                        this._translateService.instant(
                            'TIME_SERVICE.NOTIFICATIONS.WAIT'
                        )
                    );

                    let costs = (this.duration / 60) * this.costsPerHour;
                    if (this.newProjectId) {
                        this._sharepointService
                            .recycleItem({
                                listTitle: 'Zeiten',
                                id: this.itemId,
                            })
                            .then((result) => {
                                this._sharepointService
                                    .addItem({
                                        listTitle: 'Zeiten',
                                        data: {
                                            Title: 'Zeit',
                                            Datum: new Date(
                                                new Date(
                                                    new Date(
                                                        new Date(
                                                            this.date
                                                        ).setHours(12)
                                                    ).setMinutes(0)
                                                ).setSeconds(0)
                                            ),
                                            AufgabeId: this.taskId,
                                            Minuten: this.duration,
                                            ProjektId: this.newProjectId,
                                            Kosten: costs,
                                            Projektnummer: this.projectNumber,
                                            Beschreibung: this.description,
                                        },
                                        folder: this.projectId + '',
                                    })
                                    .then((result) => {
                                        this.projectId = this.newProjectId;
                                        this.newProjectId = null;

                                        this.getSumOfTimesForDate();
                                        this.timeUpdated = true;
                                        this._loadingService.hide();

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'TIME_SERVICE.NOTIFICATIONS.SUCCESS'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        resolve();
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    } else if (!this.editMode) {
                        this._sharepointService
                            .addItem({
                                listTitle: 'Zeiten',
                                data: {
                                    Title: 'Zeit',
                                    Datum: new Date(
                                        new Date(
                                            new Date(
                                                new Date(this.date).setHours(12)
                                            ).setMinutes(0)
                                        ).setSeconds(0)
                                    ),
                                    AufgabeId: this.taskId,
                                    Minuten: this.duration,
                                    ProjektId: this.projectId,
                                    Kosten: costs,
                                    Projektnummer: this.projectNumber,
                                    Beschreibung: this.description,
                                },
                                folder: this.projectId + '',
                            })
                            .then((newItemId) => {
                                // success
                                this.getSumOfTimesForDate();

                                this._loadingService.hide();

                                this._snackBarService.add(
                                    this._translateService.instant(
                                        'TIME_SERVICE.NOTIFICATIONS.SUCCESS'
                                    ),
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'success-dialog',
                                    }
                                );
                                resolve();
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                reject(error);
                            });
                    } else {
                        this._sharepointService
                            .updateItem({
                                listTitle: 'Zeiten',
                                id: this.itemId,
                                newFiledValues: {
                                    Datum: new Date(
                                        new Date(
                                            new Date(
                                                new Date(this.date).setHours(12)
                                            ).setMinutes(0)
                                        ).setSeconds(0)
                                    ),
                                    AufgabeId: this.taskId,
                                    Minuten: this.duration,
                                    ProjektId: this.projectId,
                                    Projektnummer: this.projectNumber,
                                    Kosten: costs,
                                    Beschreibung: this.description,
                                },
                            })
                            .then(() => {
                                // success
                                this.getSumOfTimesForDate();

                                this._loadingService.hide();

                                this._snackBarService.add(
                                    this._translateService.instant(
                                        'TIME_SERVICE.NOTIFICATIONS.UPDATE'
                                    ),
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'success-dialog',
                                    }
                                );
                                this.timeUpdated = true;
                                resolve();
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                reject(error);
                            });
                    }
                });
            }
        });
    }

    async initTableManualLoading(isManager, folderName) {
        const groups = [];

        if (isManager) {
            groups.push({ field: 'Author' });
        }

        if (this.level == Timelevel.Application) {
            groups.push({ field: 'Datum', dir: 'desc' });
        }

        this.tableConfig = {
            list: 'Zeiten',
            pageSize: 20,
            groups,
            columns: this.getTableColumns(isManager),
            showEditButton: false,
            showDeleteButton: false,
            recursiveAll: true,
            useMaterialDesign: true,
            toolbar: [],
            isDocumentLibrary: false,
            data: [],
            afterRefreshTable: (data, grid) => {
                this.timeGrid = grid;
            },
            customButtons: [
                {
                    name: 'Löschen',
                    icon: 'delete',
                },
            ],
        };
    }

    async initTimeTable(isManager, folderName) {
        let query = this.camlQueryCurrentUser;
        const groups = [];

        if (isManager) {
            query = this.camlQueryAll;
            groups.push({ field: 'Author' });
        }

        if (this.level == Timelevel.Application) {
            groups.push({ field: 'Datum', dir: 'desc' });
        }

        this.tableConfig = {
            list: 'Zeiten',
            pageSize: 20,
            groups,
            columns: this.getTableColumns(isManager),
            showEditButton: false,
            showDeleteButton: false,
            recursiveAll: true,
            folderName,
            useMaterialDesign: true,
            toolbar: [],
            isDocumentLibrary: false,
            camlQuery: query,
            afterRefreshTable: (data, grid) => {
                this.timeGrid = grid;
            },
            customButtons: [
                {
                    name: 'Löschen',
                    icon: 'delete',
                },
            ],
        };
    }

    public async loadTimeTableData(isManager, folderName): Promise<void> {
        let timeItems = [];

        // get time data
        if (isManager) {
            timeItems = await this.getAllTimes(folderName);
        } else {
            timeItems = await this._sharepointService.getListItems({
                title: 'Zeiten',
                isDocumentLibrary: false,
                camlQuery: this.camlQueryCurrentUser,
                recursiveAll: true,
                folderName,
            });
        }
        this.tableConfig.data = timeItems;
    }

    getSumOfTimesForDate(): Promise<any> {
        return new Promise<void>(async (resolve, reject) => {
            this._sharepointService
                .getListItems({
                    title: 'Zeiten',
                    isDocumentLibrary: false,
                    camlQuery: this.camlQueryTimesForSelectedDateAndCurrentUser,
                    recursiveAll: true,
                })
                .then((times) => {
                    this.sumOfMinutesForSelectedDate = times
                        .map((x) => x.Minuten)
                        .reduce((acc, cur) => acc + cur, 0);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getEmplyoeeProfileForCurrentUser(projectId?): Promise<any> {
        return new Promise<void>(async (resolve, reject) => {
            this._sharepointService
                .getListItems({
                    title: 'Mitarbeiter',
                    isDocumentLibrary: false,
                    camlQuery: this.camlQueryEmployeeProfileForCurrentUser,
                })
                .then((profiles) => {
                    let costRoleId = -1;

                    profiles.forEach((profile) => {
                        this.currentUserProfile = profile;
                        this._userProfileLoaded$.next(true);

                        if (profile.KostenrolleId != null) {
                            costRoleId = profile.KostenrolleId;
                        }
                    });

                    if (costRoleId > 0) {
                        if (projectId) {
                            this._sharepointService
                                .getListItems({
                                    title: 'Projekt_Kostenrollen',
                                    isDocumentLibrary: false,
                                    camlQuery: new CamlBuilder()
                                        .Where()
                                        .LookupField('Projekt')
                                        .Id()
                                        .EqualTo(projectId)
                                        .And()
                                        .LookupField('Kostenrolle')
                                        .Id()
                                        .EqualTo(costRoleId)
                                        .OrderBy('ID')
                                        .ToString(),
                                })
                                .then((costRoles) => {
                                    //debugger;
                                    if (costRoles.length > 0) {
                                        this.currentUserCostRole = costRoles[0];
                                        this.costsPerHour =
                                            costRoles[0].Stundensatz;
                                        resolve();
                                    } else {
                                        this._sharepointService
                                            .getItemById({
                                                listTitle: 'Kostenrollen',
                                                id: costRoleId,
                                            })
                                            .then((costRole) => {
                                                this.currentUserCostRole =
                                                    costRole;
                                                this.costsPerHour =
                                                    costRole.Stundensatz;

                                                resolve();
                                            })
                                            .catch((error) => {
                                                reject(error);
                                            });
                                    }
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        } else {
                            this._sharepointService
                                .getItemById({
                                    listTitle: 'Kostenrollen',
                                    id: costRoleId,
                                })
                                .then((costRole) => {
                                    this.currentUserCostRole = costRole;
                                    this.costsPerHour = costRole.Stundensatz;

                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        }
                    } else {
                        resolve();
                    }
                })
                .catch((error) => {
                    this._userProfileLoaded$.next(true);
                    reject(error);
                });
        });
    }

    getGlobalDate(date: Date): Date {
        date.setHours(date.getHours() - date.getTimezoneOffset() / 60);

        return date;
    }

    reset() {
        if (!this.editMode) {
            this.projectId = -1;
            this.projectNumber = null;
            this.duration = 0;
            this.date = new Date();
            this.taskId = -1;
            this.hours = 0;
            this.minutes = 0;
            this.description = '';
        }
        this.timeUpdated = false;
        this.costsPerHour = 0;

        this.sumOfMinutesForSelectedDate = 0;
        this.tableConfig = null;
        this.level = Timelevel.Application;
    }

    public preserve(): void {
        this.preservedValues = {
            projectId: this.projectId,
            projectNumber: this.projectNumber,
            duration: this.duration,
            date: this.date,
            taskId: this.taskId,
            hours: this.hours,
            minutes: this.minutes,
            description: this.description,
            timeUpdated: this.timeUpdated,
            sumOfMinutesForSelectedDate: this.sumOfMinutesForSelectedDate,
            tableConfig: this.tableConfig,
            level: this.level,
            costsPerHour: this.costsPerHour,
        };
    }

    public restore(): void {
        this.projectId = this.preservedValues.projectId;
        this.projectNumber = this.preservedValues.projectNumber;
        this.duration = this.preservedValues.duration;
        this.date = this.preservedValues.date;
        this.taskId = this.preservedValues.taskId;
        this.hours = this.preservedValues.hours;
        this.minutes = this.preservedValues.minutes;
        this.description = this.preservedValues.description;
        this.timeUpdated = this.preservedValues.timeUpdated;
        this.sumOfMinutesForSelectedDate =
            this.preservedValues.sumOfMinutesForSelectedDate;
        this.tableConfig = this.preservedValues.tableConfig;
        this.level = this.preservedValues.level;
        this.costsPerHour = this.preservedValues.costsPerHour;
    }

    public getUserDailyHours(): Observable<Record<string, number>> {
        return from(
            this._sharepointService.getListItems({
                title: 'Mitarbeiter',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .OrderByDesc('Datum')
                    .ToString(),
            })
        ).pipe(
            map((employees) => {
                employees.forEach(
                    (employee) =>
                        (this.dailyMitarbeiterHours[employee.UserId] =
                            employee.Tagessoll)
                );
                return this.dailyMitarbeiterHours;
            })
        );
    }

    public getTimes(start, data = [], camlQuery, projectId) {
        return this._sharepointService.web.lists
            .getByTitle('Zeiten')
            .getItemsByCAMLQuery({
                ViewXml: camlQuery,
                ListItemCollectionPosition: data.length
                    ? {
                          PagingInfo: `Paged=TRUE&p_ID=${
                              data[data.length - 1].Id
                          }`,
                      }
                    : null,
                FolderServerRelativeUrl:
                    this._sharepointService.computeServerRelativeUrl(
                        this._sharepointService.sphostUrl
                    ) + `/Zeiten/${projectId}`,
            })
            .then((response) => {
                if (response.length < 1) {
                    return data;
                }

                data.push(...response);
                return this.getTimes(false, data, camlQuery, projectId);
            });
    }

    async getAllTimes(projectId: number) {
        let items = [];
        let response = await this._sharepointService.web.lists
            .getByTitle('Zeiten')
            .items.select(
                '*',
                'Author/ID',
                'Author/Title',
                'Projekt/ID',
                'Projekt/Title',
                'Aufgabe/ID',
                'Aufgabe/Title',
                'Beschreibung'
            )
            .expand('Author', 'Projekt', 'Aufgabe')
            .top(3000)
            .getPaged();

        items.push(...response.results);

        while (response.hasNext) {
            response = await response.getNext();
            items.push(...response.results);
        }

        items.forEach((item) => {
            item.AuthorId = item.Author.ID;
            item.ProjektId = item.Projekt?.ID;
            item.TeamId = item.Team?.ID;
            item.AufgabeId = item.Aufgabe?.ID;
            item.FieldValuesAsText = {
                Author: item.Author.Title,
                Projekt: item.Projekt?.Title,
                Team: item.Team?.Title,
                Aufgabe: item.Aufgabe?.Title,
            };
            item.Aufgabe = item.Aufgabe?.Title;
            item.Author = item.Author?.Title;
            item.Projekt = item.Projekt?.Title;
            item.Team = item.Team?.Title;
            item.Datum = new Date(item.Datum);
        });

        if (projectId) {
            items = items.filter((item) => item.ProjektId === projectId);
        }

        return items;
    }

    private getTableColumns(isManager: boolean): any[] {
        return [
            {
                internalName: 'Datum',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.DATE'
                ),
                hidden: this.level == Timelevel.Application,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'Projekt',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.PROJECT'
                ),
                hidden: this.level != Timelevel.Application,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Aufgabe',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.TASK'
                ),
                hidden: this.level == Timelevel.Task,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Minuten',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.MINUTES'
                ),
                hidden: false,
            },
            {
                internalName: 'Kosten',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.COSTS'
                ),
                hidden: !isManager,
            },
            {
                internalName: 'Beschreibung',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.DESCRIPTION'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.AUTHOR'
                ),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];
    }

    public calculateDateHoursLimit(
        employeePerLocation: Record<number, any>,
        locationPerHoliday: Record<number, Holiday[]>
    ): void {
        this._userProfileLoaded$
            .pipe(
                filter((userProfileLoaded) => userProfileLoaded),
                timeout(10000),
                take(1)
            )
            .subscribe(
                () => {
                    try {
                        const today = new Date();
                        const userLocation =
                            employeePerLocation[
                                this._sharepointService.currentUser.Id
                            ];
                        const userHolidays = locationPerHoliday[userLocation];
                        const todayHoliday = userHolidays?.find(
                            (holiday) =>
                                holiday.Date.getFullYear() ===
                                    today.getFullYear() &&
                                holiday.Date.getMonth() === today.getMonth() &&
                                holiday.Date.getDate() === today.getDate()
                        );

                        let maximumUserHoursPerDay =
                            this.currentUserProfile?.Tagessoll ?? 0;

                        if (todayHoliday && maximumUserHoursPerDay > 0) {
                            maximumUserHoursPerDay =
                                maximumUserHoursPerDay -
                                maximumUserHoursPerDay * todayHoliday.Faktor;
                        }

                        this._maximumUserHoursPerDay$.next(
                            maximumUserHoursPerDay.toFixed(1)
                        );
                        this.setMaximumUserHoursPerDayLoading(false);
                    } catch (error) {
                        this._maximumUserHoursPerDay$.next(0);
                        this.setMaximumUserHoursPerDayLoading(false);
                    }
                },
                () => {
                    this._maximumUserHoursPerDay$.next(0);
                    this.setMaximumUserHoursPerDayLoading(false);
                }
            );
    }

    public async initHolidaysPerUser(): Promise<void> {
        const locationPerHoliday = await this.holidaysService.loadHolidays();
        const teams = await this._teamsService.loadAllTeams();
        this.monthlyMitarbeiterHolidayHours = {};

        for (const team of teams) {
            team.holidays = locationPerHoliday[team.StandortId];
            team.users = await this._sharepointService.web.siteGroups
                .getById(team.GruppeId)
                .users();
            team.usersIds = {};
            team.users.forEach((user) => (team.usersIds[user.Id] = true));
            this.teamsRecord[team.Id] = team;
        }

        Object.keys(this.dailyMitarbeiterHours).forEach((userIdKey) => {
            const team = teams.find(
                (team) =>
                    team.usersIds[userIdKey] &&
                    team.StandortId &&
                    team.AvailableForCalculation
            );

            if (team) {
                if (!this.teamUsers[team.Id]) {
                    this.teamUsers[team.Id] = {};
                }

                this.teamUsers[team.Id][userIdKey] = true;
            }

            team?.holidays?.find((holiday) => {
                const dateKey = DateUtil.getKeyDateToken(holiday.Date);
                if (!this.monthlyMitarbeiterHolidayHours[userIdKey]) {
                    this.monthlyMitarbeiterHolidayHours[userIdKey] = {
                        [dateKey]: 0,
                    };
                }

                if (!this.monthlyMitarbeiterHolidayHours[userIdKey][dateKey]) {
                    this.monthlyMitarbeiterHolidayHours[userIdKey][dateKey] = 0;
                }

                const dayOfWeek = holiday.Date.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                    this.monthlyMitarbeiterHolidayHours[userIdKey][dateKey] =
                        this.monthlyMitarbeiterHolidayHours[userIdKey][
                            dateKey
                        ] +
                        this.dailyMitarbeiterHours[userIdKey] * holiday.Faktor;
                }
            });
        });
    }

    public async calculateUserDailyHoursLimit(): Promise<void> {
        const currentUserId = this._sharepointService.currentUser.Id;
        const teams = await this._teamsService.loadAllTeams();
        let userTeam;
        const employeePerLocation = {};

        for (const team of teams) {
            team.users = await this._sharepointService.web.siteGroups
                .getById(team.GruppeId)
                .users();

            for (const user of team.users) {
                if (
                    user.Id === currentUserId &&
                    team.StandortId &&
                    team.AvailableForCalculation
                ) {
                    userTeam = team;
                    break;
                }
            }

            if (userTeam) {
                break;
            }
        }

        employeePerLocation[currentUserId] = userTeam?.StandortId;
        this.calculateDateHoursLimit(
            employeePerLocation,
            this.holidaysService.holidaysPerLocation
        );
    }

    public setMaximumUserHoursPerDayLoading(value: boolean): void {
        this._maximumUserHoursPerDayLoading$.next(value);
    }

    loadProjectEvaluationAllTimes(
        projectIds: number[],
        startDate: Date,
        endDate: Date,
        currentYear: number
    ): Observable<Bill[]> {
        if (!currentYear) {
            return this.getAllTime(projectIds, currentYear, startDate, endDate);
        }

        const list = this._sharepointService.web.lists.getByTitle('Zeiten');
        let allItems: Bill[] = [];

        const pageSize = 500; // Anzahl der Elemente, die pro Anfrage abgerufen werden
        let pageIndex = 0;

        const loadPage = (): Observable<Bill[]> => {
            const skip = pageIndex * pageSize;
            const chunk = projectIds.slice(skip, skip + pageSize);
            const filterExpressions = chunk
                .map((id) => `ProjektId eq ${id}`)
                .join(' or ');
            const dateFilter = `Datum ge datetime'${startDate.toISOString()}' and Datum le datetime'${endDate.toISOString()}'`;
            const filterQuery = `${filterExpressions} and ${dateFilter}`;

            return from(
                list.items
                    .filter(filterQuery)
                    .top(pageSize)()
                    .then(async (items) => {
                        allItems = allItems.concat(items);
                        if (items.length === pageSize) {
                            pageIndex++;
                            await loadPage().toPromise();
                        }
                        return allItems;
                    })
            ).pipe(map(() => allItems));
        };

        return loadPage();
    }

    getAllTime(
        projectIds: number[],
        year: number,
        filterStartDate: Date,
        filterEndDate: Date
    ): Observable<any> {
        const list = this._sharepointService.web.lists.getByTitle('Zeiten');
        const [batchedListBehavior, execute] = createBatch(list);
        let promises = [];

        this.getDateEqualChunks().forEach((item) => {
            const query = year
                ? new CamlBuilder()
                      .Where()
                      .DateField('Created')
                      .GreaterThan(item.startDate)
                      .And()
                      .DateField('Created')
                      .LessThanOrEqualTo(item.endDate)
                      .And()
                      .DateField('Datum')
                      .GreaterThanOrEqualTo(filterStartDate)
                      .And()
                      .DateField('Datum')
                      .LessThanOrEqualTo(filterEndDate)
                      .And()
                      .LookupField('Projekt')
                      .Id()
                      .In(projectIds)
                      .ToString()
                : new CamlBuilder()
                      .Where()
                      .DateField('Created')
                      .GreaterThan(item.startDate)
                      .And()
                      .DateField('Created')
                      .LessThanOrEqualTo(item.endDate)
                      .ToString();

            promises.push(
                list.items
                    .using(batchedListBehavior)
                    .filter(query)()
                    .catch((error) => {
                        console.error('Error during batch operation:', error);
                        return []; // Return an empty array in case of error
                    })
            );
        });

        return from(
            execute().then(() =>
                Promise.all(promises).then((results) => results.flat())
            )
        );
    }

    getDateEqualChunks(): { startDate: Date; endDate: Date }[] {
        const searchStartDate = new Date(2020, 0, 1);
        const searchEndDate = new Date();
        searchEndDate.setDate(searchEndDate.getDate() + 1);

        // months
        let offset = 12;
        let _startDate = new Date(searchStartDate);
        let _endDate = new Date(searchStartDate);
        _endDate.setMonth(_startDate.getMonth() + offset);
        const chunks = [];

        chunks.push({
            startDate: DateUtil.getConvertToUTCDate(_startDate),
            endDate: DateUtil.getConvertToUTCDate(_endDate),
        });

        while (_startDate.getTime() <= searchEndDate.getTime()) {
            // Startdate
            if (_startDate.getFullYear() >= 2023) {
                _startDate.setDate(_startDate.getDate() + 15);
            } else {
                _startDate.setMonth(_startDate.getMonth() + offset);
            }

            // Offset
            if (_startDate.getFullYear() === 2022) {
                offset = 6;
            } else if (_startDate.getFullYear() >= 2023) {
                offset = 1;
            }

            // Enddate
            if (_startDate.getFullYear() >= 2023) {
                _endDate.setDate(_startDate.getDate() + 15);
            } else {
                _endDate.setMonth(_startDate.getMonth() + offset);
            }

            chunks.push({
                startDate: DateUtil.getConvertToUTCDate(_startDate),
                endDate: DateUtil.getConvertToUTCDate(_endDate),
            });
        }

        return chunks;
    }

    getAllTimeByProjects(projectIds: number[]): Observable<any> {
        return forkJoin(
            this.getDateEqualChunks().map((item) => {
                return this._sharepointService.getListItems({
                    title: 'Zeiten',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .DateField('Created')
                        .GreaterThan(item.startDate)
                        .And()
                        .DateField('Created')
                        .LessThanOrEqualTo(item.endDate)
                        .And()
                        .LookupField('Projekt')
                        .Id()
                        .In(projectIds)
                        .ToString(),
                    recursiveAll: true,
                });
            })
        ).pipe(map((items) => items.flat()));
    }

    setDate(date: Date | string): void {
        if (!date) {
            this.date = null;
        } else if (typeof date === 'string') {
            this.date = new Date(date);
        } else {
            this.date = date;
        }
    }
}
